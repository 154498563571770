/** @format */

import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { radioSelect } from '../../recoil/recoilAtom';
import RadioInput from './RadioInput';
import '../../style/pcom/_pdp-bundleOption.scss';

export default function BundleOption({ bundleItem, onOptionChange, platform, title}) {
	const { subject } = useParams();
	const { bundle } = bundleItem.option;
	const [selectedOption, setSelectedOption] = useRecoilState(radioSelect);
	const handleBundleOption = (value) => {
		setSelectedOption(value);
		onOptionChange(value);
	};
	useEffect(() => {
		if (subject !== 'sociology') {
			setSelectedOption(bundle.radioLabel.single);
		}
	}, []);

	return (
		<div className={`bundle-option-container ${selectedOption === bundle.radioLabel.single || selectedOption === bundle.radioLabel.multi ? 'radio-checked': ''}`}>

			<h3 className="header">{bundleItem.header}</h3>
			<div className="option-info">

				{/*Only show card header if product type is MASTERING or MYLAB*/}
				{platform !== "eTextbook" && (
					<>
						{selectedOption === bundle.radioLabel.multi ? (
				  			<h4>{platform} <span className="capitalize">{subject} </span>with Pearson eText ({bundle.radioLabel.multi[4].replace('Up to ', '')}) for {title} + Study & Exam Prep Pack</h4>
						) : (
							<h4>{platform} <span className="capitalize">{subject} </span>with Pearson eText ({bundle.radioLabel.single[4].replace('Up to ', '')}) for {title} + Study & Exam Prep Pack</h4>
						)}
					</>
				)}

				<RadioInput
					id="bundle-single-option"
					name="bundleOption"
					label={subject === 'chemistry' ? 'eTextbook + Study & Exam Prep Pack' : 'Single-term access'}
					value={bundle.radioLabel.single}
					checked={selectedOption === bundle.radioLabel.single}
					onChange={handleBundleOption}
				/>

				{bundle.radioLabel.multi.length > 0 && (
					<RadioInput
						id="bundle-multi-option"
						name="bundleOption"
						label='Multi-term access'
						value={bundle.radioLabel.multi}
						checked={bundle.radioLabel.multi === selectedOption}
						onChange={() => handleBundleOption(bundle.radioLabel.multi)}
					/>
				)}
			</div>
		</div>
	);
}
