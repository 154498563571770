/** @format */

import { atom } from "recoil";

export const radioSelect = atom({
	key: "radioSelect",
	default: null,
});

export const subjectSelect = atom({
	key: "subjectSelect",
	default: null,
});

export const openAccordion = atom({
	key: "openAccordion",
	default: true,
});

export const closeAccordion = atom({
	key: "closeAccordion",
	default: false,
});

export const textInput = atom({
	key: "textInput",
	default: "",
});

export const errorStatus = atom({
	key: "errorStatus",
	default: false,
});

export const PcomLoginState = atom({
	key: "PcomLoginState",
	default: false,
});


export const SmsLoginState = atom({
	key: "SmsLoginState",
	default: false,
});

