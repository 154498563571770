/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
// import styled from 'styled-components';

import PsoLogo from '../../assets/image/pcom-reskin/logo-on-dark.svg';

export default function FooterSuperGateway() {
  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  return (
    <footer>
      <div>
        <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
          <div className="iparsys parsys aem-GridColumn aem-GridColumn--default--12">
            <div className="experiencefragment section">
              <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                <section className="column-control he-footer-global layout-default bgcolor--default clipping-default gradients-default default-animation default-gutters has-padding-top--none has-padding-bottom--none default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow aem-GridColumn aem-GridColumn--default--12">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <section className="column-control layout-default bgcolor--default clipping-default gradients-default default-animation default-gutters default-padding-top default-padding-bottom default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow section c-footer__main">
                          <div className="row">
                            <div className="col-md-3 col-12">
                              <div className="logo-v2 section c-footer__logo">
                                <Link id="footer__vCake-logo" to={`${process.env.PUBLIC_URL}/home`}>
                                  <picture>
                                    <img src={PsoLogo} alt="Pearson Home" />
                                  </picture>
                                </Link>
                              </div>
                              <div className="social-links c-footer__social-links section">

                                {/*For some reason, this UL is getting display:block, so have to override*/}
                                <ul className="social-links__list" style={{'display': 'inline-flex'}}>
                                  <li className="social-links__item">
                                    <a onClick={stopIt} href="https://www.facebook.com/PearsonNorthAmerica" target="_blank" rel="noreferrer" aria-label="Facebook" className="social-links--facebook"></a>
                                  </li>
                                  <li className="social-links__item">
                                    <a onClick={stopIt} href="https://twitter.com/PearsonNorthAm" target="_blank" rel="noreferrer" aria-label="Twitter" className="social-links--twitter"></a>
                                  </li>
                                  <li className="social-links__item">
                                    <a onClick={stopIt} href="https://www.youtube.com/c/PearsonNorthAmerica" target="_blank" rel="noreferrer" aria-label="Youtube" className="social-links--youtube"></a>
                                  </li>
                                  <li className="social-links__item">
                                    <a onClick={stopIt} href="https://www.linkedin.com/showcase/pearson-north-america/" target="_blank" rel="noreferrer" aria-label="LinkedIn" className="social-links--linkedin"></a>
                                  </li>
                                  <li className="social-links__item">
                                    <a onClick={stopIt} href="https://www.instagram.com/pearsonofficial/" target="_blank" rel="noreferrer" aria-label="Instagram" className="social-links--instagram"></a>
                                  </li>
                                </ul>
                              </div>
                              <div className="parsys section"></div>
                              <div className="parsys section">
                                <div className="parsys section">
                                  <div className="parsys section">
                                    <div className="locale-selection he-nav--mobile-only section">
                                      <div>
                                        <button className="locale-selection__button selected_en-US" aria-haspopup="true" aria-label="Locale Selection. Currently selected: United States" aria-expanded="false" aria-controls="locale-selection-dropdown">
                                        United States
                                        </button>
                                        <div className="locale-selection__dropdown" aria-describedby="locale-selection-description" id="locale-selection-dropdown">
                                          <ul role="menu" aria-label="Locale Selection">
                                            <li role="none">
                                              <a onClick={stopIt} role="menuitem" lang="en-US" hreflang="en-US" href="https://www.pearson.com/en-us.html" aria-selected="true">
                                              United States</a>
                                            </li>
                                            <li role="none">
                                              <a onClick={stopIt} role="menuitem" lang="en-CA" hreflang="en-CA" href="https://www.pearson.com/en-ca.html">
                                              Canada</a>
                                            </li>
                                            <li role="none">
                                              <a onClick={stopIt} role="menuitem" lang="en-GB" hreflang="en-GB" href="https://www.pearson.com/en-gb.html">
                                              United Kingdom</a>
                                            </li>
                                            <li role="none">
                                              <a onClick={stopIt} role="menuitem" lang="en" hreflang="en" href="https://www.pearson.com/en-us/location-selector.html">
                                              All countries</a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="parsys section">
                                    <div className="parsys section"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="parsys section"></div>
                            </div>
                            <div className="col-md-9 col-12">
                              <section className="column-control layout-default bgcolor--default clipping-default gradients-default default-animation default-gutters has-padding-top--none has-padding-bottom--none default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow section c-footer__navigation">
                                <div className="row">
                                  <div className="col-20pct col-12">
                                    <div className="accordion acord section">
                                      <section className="accordion-panel">
                                        <h3 className="accordion-panel-header">
                                          <button aria-controls="-2033578333-1" aria-expanded="false">
                                          <span>Explore</span>
                                          <span className="pull-right fa fa-plus"></span>
                                          </button>
                                          <span className="accordion-panel-header__text">
                                          Explore
                                          </span>
                                        </h3>
                                        <div className="accordion-panel-content accordion-panel-hide" id="-2033578333-1">
                                          <div className="link-list default section c-footer__link-list">
                                            <div>
                                              <ul id="mn-root--842179">
                                                <li>
                                                  <a onClick={stopIt} href="https://www.pearson.com/en-us/schools.html">Schools</a>
                                                </li>
                                                <li>
                                                  <Link to={`${process.env.PUBLIC_URL}/higher-education`}>College</Link>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="https://www.pearson.com/en-us/work.html">Work</a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </div>
                                  </div>




                                  <div className="col-20pct col-12">
                                    <div className="accordion section">
                                      <section className="accordion-panel">
                                        <h3 className="accordion-panel-header">
                                          <button aria-controls="1750854786-1" aria-expanded="false">
                                          <span>Support</span>
                                          <span className="pull-right fa fa-plus"></span>
                                          </button>
                                          <span className="accordion-panel-header__text">
                                          Support
                                          </span>
                                        </h3>
                                        <div className="accordion-panel-content accordion-panel-hide" id="1750854786-1">
                                          <div className="link-list section c-footer__link-list">
                                            <div>
                                              <ul id="mn-root-90893990">
                                                <li>
                                                  <a onClick={stopIt} href="/">Educator support</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="/">Student support</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="/">College support</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="/">Find your rep</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="/">Global permissions</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="/">Global rights licensing</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="/">Report piracy</a>
                                                </li>

                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </div>
                                  </div>




                                  <div className="col-20pct col-12">
                                    <div className="accordion section">
                                      <section className="accordion-panel">
                                        <h3 className="accordion-panel-header">
                                          <button aria-controls="-822487702-1" aria-expanded="false">
                                          <span>Insights</span>
                                          <span className="pull-right fa fa-plus"></span>
                                          </button>
                                          <span className="accordion-panel-header__text">
                                          Insights
                                          </span>
                                        </h3>
                                        <div className="accordion-panel-content accordion-panel-hide" id="-822487702-1">
                                          <div className="link-list default section c-footer__link-list">
                                            <div>
                                              <ul id="mn-root-426518724">
                                                <li>
                                                  <a onClick={stopIt} href="/" target="_blank" className="new-window" rel="noreferrer">PreK–12 blog</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="/" target="_blank" className="new-window" rel="noreferrer">Teaching &amp; learning blog</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="/" target="_blank" className="new-window" rel="noreferrer">Students blog</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="/" target="_blank" className="new-window" rel="noreferrer">Webinar &amp events</a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </div>
                                  </div>









                                  <div className="col-20pct col-12">
                                    <div className="accordion section">
                                      <section className="accordion-panel">
                                        <h3 className="accordion-panel-header">
                                          <button aria-controls="1074786963-1" aria-expanded="false">
                                          <span>Major businesses</span>
                                          <span className="pull-right fa fa-plus"></span>
                                          </button>
                                          <span className="accordion-panel-header__text">
                                          Major businesses
                                          </span>
                                        </h3>
                                        <div className="accordion-panel-content accordion-panel-hide" id="1074786963-1">
                                          <div className="link-list default section c-footer__link-list">
                                            <div>
                                              <ul id="mn-root--1159223763">
                                                <li>
                                                  <a onClick={stopIt} href="/">Clinical assessments</a>
                                                </li>
                                                 <li>
                                                  <a onClick={stopIt} href="/">Pearson languages</a>
                                                </li>
                                                 <li>
                                                  <a onClick={stopIt} href="/">Pearson VUE</a>
                                                </li>
                                                 <li>
                                                  <a onClick={stopIt} href="/">Virtual schools</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="/">Online schools</a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </div>
                                  </div>


                                  <div className="col-20pct col-12">
                                    <div className="accordion section">
                                      <section className="accordion-panel">
                                        <h3 className="accordion-panel-header">
                                          <button aria-controls="-822487702-1" aria-expanded="false">
                                          <span>About us</span>
                                          <span className="pull-right fa fa-plus"></span>
                                          </button>
                                          <span className="accordion-panel-header__text">
                                          About us
                                          </span>
                                        </h3>
                                        <div className="accordion-panel-content accordion-panel-hide" id="-822487702-1">
                                          <div className="link-list default section c-footer__link-list">
                                            <div>
                                              <ul id="mn-root-426518724">
                                                <li>
                                                  <a onClick={stopIt} href="https://pearson.jobs/" target="_blank" className="new-window" rel="noreferrer">Careers</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="https://plc.pearson.com/en-GB/investors" target="_blank" className="new-window" rel="noreferrer">Investor relations</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="https://plc.pearson.com/en-GB/company" target="_blank" className="new-window" rel="noreferrer">Our company</a>
                                                </li>
                                                <li>
                                                  <a onClick={stopIt} href="https://plc.pearson.com/en-GB/careers/diversity-equity-inclusion" target="_blank" className="new-window" rel="noreferrer">Diversity &amp; inclusion</a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </div>
                                  </div>


                                </div>
                              </section>
                            </div>
                          </div>
                        </section>
                        <section className="column-control layout-default  clipping-default gradients-default default-animation default-gutters has-padding-top--30 has-padding-bottom--30 default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow section c-footer__main c-footer__bottom">
                          <div className="row">
                            <div className="col-12">
                              <div className="link-list default section c-footer__link-list">
                                <div>
                                  <ul id="mn-root-2107762321">
                                    <li>
                                      <a onClick={stopIt} href="https://www.pearson.com/en-us/legal-information/terms-of-use.html">Terms of use</a>
                                    </li>
                                    <li>
                                      <a onClick={stopIt} href="https://www.pearson.com/en-us/privacy-center/privacy-notices.html">Privacy</a>
                                    </li>
                                    <li>
                                      <a onClick={stopIt} href="https://www.pearson.com/en-us/legal-information/cookie-policy.html" aria-label="Cookie Settings" id="open-ot-preference-modal">Cookies</a>
                                    </li>
                                    <li>
                                      <a onClick={stopIt} href="https://www.pearson.com/en-us/legal-information/cookie-policy.html#do-not-sell-or-share-my-data">Do not sell my personal information</a>
                                    </li>
                                    <li>
                                      <a onClick={stopIt} href="https://www.pearson.com/en-us/legal-information/accessibility.html">Accessibility</a>
                                    </li>
                                    <li>
                                      <a onClick={stopIt} href="https://www.pearson.com/en-us/legal-information/patent-notice.html">Patent notice</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="text section c-footer__bottom-copyright">
                                <p style={{'color':'var(--color--reskin--light-purple)'}}>© 1996–2024 Pearson All rights reserved.</p>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                  <div className="footerServiceModalsWrapper"></div>
                </section>
              </div>
            </div>
            <div className="section">
              <div className="new"></div>
            </div>
            <div className="iparys_inherited">
              <div className="iparsys parsys"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
