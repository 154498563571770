/** @format */

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { PcomLoginState, SmsLoginState } from "../../recoil/recoilAtom";

/**components */
import PsoLogo from "../global/PsoLogo";
import Search from "./Search";
import NavIcons from "../global/NavIcons";
import PrimaryButton from "../global/PrimaryButton";
import NavAccountDropdown from "../global/NavAccountDropdown";

import "../../style/pcom/_nav.scss";

import IconSecureCheckout from "../../assets/image/secure-checkout.png";
import IconChevronLeft from "../../assets/image/icon-chevron-left.png";

export default function Nav({ isTransparent, isSticky, mode, returnTitle , pageBack}) {
  const [PcomLoggedIn, setPcomLoggedIn] = useRecoilState(PcomLoginState);
  const [SmsLoggedIn, setSmsLoggedIn] = useRecoilState(SmsLoginState);

  const navigate = useNavigate();

  function handleReturn() {
    navigate(pageBack);
  }

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  return (
    <div className={`PCOM ${isSticky == 'true' ? "nav-is-sticky" : ""}`}>
      {mode != "checkout" ? (
        <div className={`nav-wrapper ${isTransparent == 'true' ? "is-transparent" : ""}`}>

          <nav>{/* TODO: Apply className="is-stuck" when grandparent <div> becomes "stuck" */}
            <div className="menu-wrapper">
              <PsoLogo />
              <ul className="menu-list">
                <li><Link to={process.env.PUBLIC_URL + '/higher-education/students'}>Students</Link></li>
                <li><Link to={process.env.PUBLIC_URL + '/higher-education/educators'} onClick={stopIt}>Educators</Link></li>
                <li><Link to={process.env.PUBLIC_URL + '/'} onClick={stopIt}>Browse by subject</Link></li>
              </ul>
            </div>

            <Search style={{ width: "40rem" }} />
            <div className="function-wrapper">
              <NavIcons style={{ marginLeft: "2.5rem" }} />

              {PcomLoggedIn || SmsLoggedIn ? (
                <NavAccountDropdown />
              ) : (
                <PrimaryButton
                  style={{ marginLeft: "1rem", backgroundColor: "white", borderColor: "white" }}
                  title="Sign in"
                />
              )}
            </div>
          </nav>

        </div>
      ) : (
        <div className="nav-wrapper">

          <nav>{/* Checkout nav is never sticky; no logic for that is needed here */}
            <div className="menu-wrapper">
              <PsoLogo />
            </div>

            <div className="function-wrapper">
              <p className="secure-checkout">
                Secure Checkout
                <img src={IconSecureCheckout} alt="" />
              </p>
            </div>
          </nav>

          <div className="crumbtrail">
            <button onClick={handleReturn} className="return">
              <img src={IconChevronLeft} alt="" />
              {returnTitle}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
