/** @format */

import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

/**styles */
import '../../style/pcom-reskin/_ganked.scss';

/** images **/
import imgPromoA from "../../assets/image/pcom-reskin/ganked/students/promo-a.jpg";
import imgPromoB from "../../assets/image/pcom-reskin/ganked/students/promo-b.jpg";
import imgPromoC from "../../assets/image/pcom-reskin/ganked/students/promo-c.jpg";
import imgPromoD from "../../assets/image/pcom-reskin/ganked/students/promo-d.jpg";

import imgPromoE from "../../assets/image/pcom-reskin/ganked/educators/promo-e.jpg";
import imgPromoF from "../../assets/image/pcom-reskin/ganked/educators/promo-f.jpg";
import imgPromoG from "../../assets/image/pcom-reskin/ganked/educators/promo-g.jpg";
import imgPromoH from "../../assets/image/pcom-reskin/ganked/educators/promo-h.jpg";

/**components */
import TopStrapNav from '../../components/pcom-reskin/TopStrapNav';
import Nav from '../../components/pcom-reskin/Nav';
import FooterCollege from '../../components/pcom-reskin/FooterCollege';


export default function Educators() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

	return (
		<>
      <TopStrapNav collegeActive='true' />
      <Nav isTransparent='true' isSticky='true' />

      <div className="PCOM-GANKED educators">
        <div className="page basicpage dots-lrDown-textureLight headerIsTransparent">

        <h1 style={{'color': 'red', 'textAlign': 'center', 'position': 'absolute', 'top': '80px', 'left': '0', 'zIndex': '3000'}}>Educators Placeholder - STRETCH GOAL</h1>

          <main role="main" id="main-content-starts">
            <div>
              <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                <section class="column-control flex-layout--vertically-centered curve-bottom-right clip-target-bg-media default-texture default-animation default-gutters has-padding-top--90 has-padding-bottom--90 default-padding-horizontal min-height--600 default-rule default-corner-radius default-shadow aem-GridColumn aem-GridColumn--default--12">
                  <div class="column-control--bg bg168781763 column-control--mask-dark-gradient"></div>
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <div class="text screenreader section">
                          <h1>Educator collection</h1>
                        </div>
                        <div class="content-tile use-margin-top-none color--text-primary-inverse title-typesize--h1 section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <h2 class="content-tile__title">Step into a new way of teaching </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content-tile default-design-template behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default default-links use-button-primary-inverse use-button-large default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-left--0__pad-right--30__caption title-typesize--h5 title-typeweight--bold title-color--text-primary-inverse subtitle-typesize--h5 subtitle-typeweight--bold subtitle-color--text-tertiary-inverse default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <p class="content-tile-subtitle">Take students’ learning — and your instruction — to a whole new level</p>
                                <div class="content-tile-text rte-container">
                                  <p><a href="/en-us/search.html" aria-label="Browse catalog of educator resources" onClick={stopIt} className="stopIt">Browse catalog</a><br /></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12"></div>
                    </div>
                  </div>
                </section>
                <section class="column-control has-padding-top--30 has-padding-bottom--45 aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-2 col-12"></div>
                      <div class="col-md-8 col-12">
                        <div class="content-tile section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <div class="content-tile-text rte-container">
                                  <h2 style={{'text-align': 'center'}}>Tech for personalized teaching</h2>
                                  <p style={{'text-align': 'center'}}>Help your students unlock their potential with interactive course content, assessments, and apps</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 col-12"></div>
                    </div>
                  </div>
                </section>
                <section class="column-control flex-layout--pin-cta has-padding-top--none aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-xl-3 col-md-6 col-12">
                        <div class="content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h5 default-title-typefamily default-title-typeweight default-title-color default-subtitle-typesize default-subtitle-typefamily default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{"--original-image-ratio": "2"}}>
                                <img loading="lazy" src={imgPromoA} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">MyLab</h3>
                                <div class="content-tile-text rte-container">
                                  <p>Data-driven guidance to help improve results with engaging, interactive content by expert authors<br /></p>
                                  <p><a adhocenable="false" href="https://www.pearson.com/en-us/higher-education/products-services/mylab.html" onClick={stopIt} className="stopIt">Explore MyLab<sup>®</sup></a><br /></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12">
                        <div class="content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h5 default-title-typefamily default-title-typeweight default-title-color default-subtitle-typesize default-subtitle-typefamily default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{"--original-image-ratio": "2"}}>
                                <img loading="lazy" src={imgPromoB} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">Mastering</h3>
                                <div class="content-tile-text rte-container">
                                  <p>Tools to help create immersive science &amp; engineering courses that increase engagement<br /></p>
                                  <p><a adhocenable="false" href="https://www.pearson.com/en-us/higher-education/products-services/mastering.html" onClick={stopIt} className="stopIt">Explore Mastering<sup>®</sup></a><br /></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12">
                        <div class="content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h5 default-title-typefamily default-title-typeweight default-title-color default-subtitle-typesize default-subtitle-typefamily default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{"--original-image-ratio": "2"}}>
                                <img loading="lazy" src={imgPromoC} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">Revel</h3>
                                <div class="content-tile-text rte-container">
                                  <p>A dynamic mobile reading experience that blends digital text and media to bring concepts to life<br /></p>
                                  <p><a adhocenable="false" href="https://www.pearson.com/en-us/higher-education/products-services/revel.html" onClick={stopIt} className="stopIt">Explore Revel<sup>®</sup></a><br /></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12">
                        <div class="content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h5 default-title-typefamily default-title-typeweight default-title-color default-subtitle-typesize default-subtitle-typefamily default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{"--original-image-ratio": "2"}}>
                                <img loading="lazy" src={imgPromoD} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">eTextbooks and Pearson+</h3>
                                <div class="content-tile-text rte-container">
                                  <p>The engaging content digital natives need, in the format they prefer, at a price point they expect<br /></p>
                                  <p><a adhocenable="false" href="https://www.pearson.com/en-us/higher-education/products-services/pearsonplus.html" onClick={stopIt} className="stopIt">Explore eTextbooks</a><br /></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="column-control bgcolor--ui-01 aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-2 col-12"></div>
                      <div class="col-md-8 col-12">
                        <div class="content-tile pad-top--0__pad-bottom--30__caption section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <div class="content-tile-text rte-container">
                                  <h2 style={{'text-align': 'center'}}>Higher ed resources by discipline</h2>
                                  <p style={{'text-align': 'center'}}>Search our featured disciplines for digital solutions supporting you and your students</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content-tile use-button-tertiary section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <div class="content-tile-text rte-container">
                                  <p style={{'text-align': 'center'}}>
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/brady.html" aria-label="Search Brady">Brady EMS</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/business-economics.html" aria-label="Search Business &amp; Economics">Business &amp; Economics</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/computer-science-programming.html" aria-label="Search Computer Science &amp; Programming" >Computer Science &amp; Programming</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/engineering.html" aria-label="Search Engineering" >Engineering</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/english.html" aria-label="Search English">English</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/nursing-health-professions.html" aria-label="Search Health Sciences">Health Sciences</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/humanities-social-sciences.html" aria-label="Search Humanities &amp; Social Sciences">Humanities &amp; Social Sciences</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/information-technology-mis.html" aria-label="Search Information Technology &amp; MIS">Information Technology &amp; MIS</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/math-statistics.html" aria-label="Search Math &amp; Statistics">Math &amp; Statistics</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/nccer.html" aria-label="Search NCCER">NCCER Construction Trades</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/professional-career.html" aria-label="Search Professional &amp; Career">Professional &amp; Career</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/psychology.html" aria-label="Search Psychology">Psychology</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/sciences.html" aria-label="Search Sciences">Sciences</a>&nbsp;
                                    <a onClick={stopIt} className="stopIt" href="https://www.pearson.com/en-us/higher-education/resources-by-discipline/teacher-education-helping-professions.html" aria-label="Search Teacher Education &amp; Helping Professions">Teacher Education &amp; Helping Professions</a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content-tile use-button-primary section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <div class="content-tile-text rte-container">
                                  <p style={{'text-align': 'center'}}><a href="/en-us/search.html" aria-label="Browse all subjects" onClick={stopIt} className="stopIt">Browse all subjects</a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 col-12"></div>
                    </div>
                  </div>
                </section>
                <section class="column-control bgcolor--background-dark aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-2 col-12"></div>
                      <div class="col-md-8 col-12">
                        <div class="content-tile use-button-tertiary-inverse default-btn-size pad-top--0__pad-bottom--15__caption color--text-primary-inverse section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <div class="content-tile-text rte-container">
                                  <h2 style={{'text-align': 'center'}}>Pearson eTextbook</h2>
                                  <p style={{'text-align': 'center'}}><b>What’s on the inside just might surprise you</b></p>
                                  <p style={{'text-align': 'center'}}>Meet students right where they are with engaging learning experiences that go beyond the textbook</p>
                                  <p style={{'text-align': 'center'}}><a href="https://www.pearson.com/en-us/higher-education/products-services/pearsonplus.html" onClick={stopIt} className="stopIt">Learn more about eTextbooks</a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="youtube-video section">
                          <iframe id="404096962" class="youtube-video-iframe" data-video-id="TCIok0KnHDc" data-related-videos="" src="" title="Pearson eText: What’s on the inside just might surprise you" allowfullscreen=""> </iframe>
                          <img class="youtube-video-inline-img" src="https://img.youtube.com/vi/TCIok0KnHDc/maxresdefault.jpg" alt="Pearson eText: What’s on the inside just might surprise you" /> <span class="youtube-video-play-img"></span>
                          <div class="background-img-overlay"></div>
                          <span class="sr-only">Play</span>
                          <div class="yt-overlay">
                            <div class="overlay-text-wrapper">
                              <div class="overlay-header"> Privacy and cookies</div>
                              <div class="overlay-text">
                                By watching, you agree Pearson can share your viewership data for marketing and analytics for one year, revocable by deleting your cookies.
                              </div>
                            </div>
                            <button class="overlayButton"> Accept</button>
                          </div>
                        </div>
                        <div class="text weight--semibold-plus color--text-primary-inverse section">
                          <p style={{"text-align": "left"}}><span class="minor"><a href="/content/dam/one-dot-com/one-dot-com/global/Files/2022-PearsonPlus-eTextbooks-60sec-final.pdf" target="_blank" aria-label="Read the transcript. Opens new tab. PDF 21.55 KB" rel="noreferrer" onClick={stopIt} className="stopIt">Read the transcript<span class="file-type-size"> (<span class="extension">PDF</span> | <span class="filesize">21.55 KB</span>)</span></a></span></p>
                          <p style={{"text-align": "right"}}>&nbsp;</p>
                        </div>
                      </div>
                      <div class="col-md-2 col-12"></div>
                    </div>
                  </div>
                </section>
                <section class="column-control bgcolor--background-gray aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <div class="content-tile content-tile-color-block--full-img--text-right behavior--none content-tile-portrait default-layout-alignment bgcolor--background-dark default-links use-button-tertiary-inverse default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption color--text-primary-inverse title-typesize--h2 default-title-typeweight default-title-color subtitle-typesize--h4 default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{"--original-image-ratio": "2"}}>
                                <img loading="lazy" src={imgPromoE} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h2 class="content-tile__title">Digital Learning NOW</h2>
                                <p class="content-tile-subtitle">Expand your teaching strategies</p>
                                <div class="content-tile-text rte-container">
                                  <p>Inspire students and expand your development with live and on-demand sessions on current edtech trends.<br /></p>
                                  <p><a href="https://www.pearson.com/en-us/higher-education/insights-and-events/webinars-events.html" onClick={stopIt} className="stopIt">Explore webinars</a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="column-control bgcolor--background-medium has-padding-bottom--45 aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-2 col-12"></div>
                      <div class="col-md-8 col-12">
                        <div class="content-tile default-design-template behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default default-links default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-title-typesize default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <div class="content-tile-text rte-container">
                                  <h2 style={{'text-align': 'center'}}>Blogs &amp; news</h2>
                                  <p style={{'text-align': 'center'}}>Explore insights, trends, and research that impact teaching, learning, and leading</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 col-12"></div>
                    </div>
                  </div>
                </section>
                <section class="column-control flex-layout--pin-cta bgcolor--background-medium has-padding-top--none default-padding-bottom aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-4 col-12">
                        <div class="content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h5 default-title-typefamily default-title-typeweight default-title-color default-subtitle-typesize default-subtitle-typefamily default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow default-links section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{"--original-image-ratio": "2"}}>
                                <img loading="lazy" src={imgPromoF} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">MediaShare to Shared Media</h3>
                                <p class="content-tile-subtitle">Dr. Terri Moore</p>
                                <div class="content-tile-text rte-container">
                                  <p>I’ve been teaching public speaking for over 25 years. When I decided to teach online 15 years ago, I looked for a tool that would allow…<br /></p>
                                  <p>March 31, 2022</p>
                                  <p><a href="https://www.pearson.com/en-us/higher-education/insights-and-events/teaching-and-learning-blog/2022/03/mediashare-to-shared-media.html" aria-label="Read Media Share to Shared Media" onClick={stopIt} className="stopIt">Read&nbsp;Media Share to Shared Media</a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h5 default-title-typefamily default-title-typeweight default-title-color default-subtitle-typesize default-subtitle-typefamily default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{"--original-image-ratio": "2"}}>
                                <img loading="lazy" src={imgPromoG} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">#BreakTheBias in Biology</h3>
                                <p class="content-tile-subtitle">Dr. Lourdes Norman-McKay and Dr. Lisa Urry</p>
                                <div class="content-tile-text rte-container">
                                  <p>74% of women believe all types of bias and discrimination are still making it difficult to find new career opportunities, according to …<br /></p>
                                  <p>March 8, 2022</p>
                                  <p><a href="https://www.pearson.com/en-us/higher-education/insights-and-events/teaching-and-learning-blog/2022/03/breakthebias-in-biology.html" aria-label="Read #BreakTheBias in Biology" onClick={stopIt} className="stopIt">Read&nbsp;#BreakTheBias in Biology</a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h5 default-title-typefamily default-title-typeweight default-title-color default-subtitle-typesize default-subtitle-typefamily default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{"--original-image-ratio": "2"}}>
                                <img loading="lazy" src={imgPromoH} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">Harnessing the power of positivity in higher ed</h3>
                                <p class="content-tile-subtitle">Pearson</p>
                                <div class="content-tile-text rte-container">
                                  <p>There's a well-known quote from Norman Vincent Peale’s famous book, The Power of Positive Thinking, that says, “Our happiness depends o…<br /></p>
                                  <p>February 18, 2022</p>
                                  <p><a href="https://www.pearson.com/ped-blogs/blogs/2022/02/harnessing-the-power-of-positivity-in-higher-ed.html" aria-label="Read Harnessing the power of positivity in higher ed" onClick={stopIt} className="stopIt">Read&nbsp;Harnessing the power of positivity in higher ed</a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="column-control default-padding-bottom aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6 col-12 order-md-2">
                        <div class="text section">
                          <h2 style={{'text-align': 'center'}}>Explore additional solutions<br /></h2>
                        </div>
                        <div class="content-tile default-design-template behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--background-light default-links use-button-secondary default-btn-size default-btn-width pad--30__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-title-typesize default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule corners-semiround-sm boxshadow-sm section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <div class="content-tile-text rte-container">
                                  <h3 style={{'text-align': 'center'}}>Instructors</h3>
                                  <p style={{'text-align': 'center'}}>Inspire students to unlock potential and achieve more with personalized content, resources and apps.</p>
                                  <p style={{'text-align': 'center'}}><a adhocenable="false" href="https://www.pearson.com/en-us/higher-education/products-services.html" aria-label="Browse offerings for instructors" onClick={stopIt} className="stopIt">Browse offerings</a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-12 order-md-1"></div>
                      <div class="col-md-3 col-12 order-md-3"></div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div aria-live="assertive" style={{"position": "absolute", "color": "transparent", "bottom": "26px"}} id="accessibility__announcement"></div>
            <a class="to-top-button" href="#main-content-starts">
            <span class="screenreader">Back to</span>
            <span class="to-top-button__title">top</span>
            </a>
          </main>


        </div>

        <FooterCollege />

      </div>
    </>
	);
}
