/** @format */

import React from "react";
import styled from "styled-components";

export default function ApplyButton({ buttonProps }) {
	const { handleApply, disabled } = buttonProps;

	return (
		<ApplyButtonContainer onClick={handleApply} disabled={disabled}>
			Apply
		</ApplyButtonContainer>
	);
}

const ApplyButtonContainer = styled.button`
	background-color: var(--color--tertiary-action);
	color: var(--color-text-primary, #151515);
	border-radius: 40px;
	border: 2px solid var(--interactive-tertiary, #151515);
	font-size: 1rem;
	cursor: pointer;
	font-family: var(--typefamily--primary-stack);
	font-weight: 700;
	padding: 0 1.5rem;
	height: 48px;
	align-items: center;
	min-width: 11.25rem;

	&:hover {
		background-color: #151515;
		border: 2px solid #151515;
		color: var(--color--text-primary-inverse);
	}
	&:focus {
		border: 3px solid var(--interactive-focus-field, #2192fb);
		background-color: var(--color--tertiary-action);
		color: #151515;
	}
	&:disabled {
		background: var(--color--tertiary-action);
		border: 2px solid var(--interactive-01-disabled, #e6e6e6);
		color: #919191;
	}
`;
