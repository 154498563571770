/** @format */

import { useState } from 'react';

/*icon*/
import { IoCheckmarkCircle } from 'react-icons/io5';
import { IconContext } from 'react-icons';
/*style*/
import '../../style/pcom/_pdp-toggleSwitch.scss';

export default function ToggleSwitch() {
	const [switchOn, setSwitchOn] = useState(false);

	const handleToggle = () => {
		setSwitchOn(!switchOn);
	};

	return (
		<div
			className="toggle-switch-container"
		//	onClick={handleToggle}
		>
			<span
				className="text-left"
				style={{ visibility: switchOn ? 'visible' : 'hidden' }}
			>
				I'm a student
			</span>

			<div
				className="slider"
				style={{ left: switchOn ? '200px' : '0px' }}
			>
				<IconContext.Provider
					value={{
						style: {
							verticalAlign: 'middle',
							color: '#151515',
							width: '16px',
							marginRight: '10px',
						},
					}}
				>
					<IoCheckmarkCircle />
					{switchOn ? "I'm an educator" : "I'm a student"}
				</IconContext.Provider>
			</div>

			<span
				className="text-right"
				style={{ visibility: switchOn ? 'hidden' : 'visible' }}
			>
				I'm an educator
			</span>
		</div>
	);
}
