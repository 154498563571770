/** @format */

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { PcomLoginState, SmsLoginState } from "../../recoil/recoilAtom";

/**components */
import PsoLogo from "../global/PsoLogo";
import Search from "./Search";
import NavIcons from "../global/NavIcons";
import PrimaryButtonMotion from "../motion/PrimaryButtonMotion";
import NavAccountDropdown from "../global/NavAccountDropdown";

import "../../style/pcom/_nav.scss";

import IconSecureCheckout from "../../assets/image/secure-checkout.png";
import IconChevronLeft from "../../assets/image/icon-chevron-left.png";

export default function NavSuperGateway({ isTransparent, isSticky, mode, returnTitle , pageBack}) {
  const [PcomLoggedIn, setPcomLoggedIn] = useRecoilState(PcomLoginState);
  const [SmsLoggedIn, setSmsLoggedIn] = useRecoilState(SmsLoginState);

  const navigate = useNavigate();

  function handleReturn() {
    navigate(pageBack);
  }

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  // Scroll listener is in place, but it is an outside script in index.html

  return (
    <div className={`PCOM ${isSticky == 'true' ? "nav-is-sticky" : ""}`}>
      <div className={`nav-wrapper ${isTransparent == 'true' ? "is-transparent" : ""}`}>

        <nav>{/* TODO: Apply className="is-stuck" when grandparent <div> becomes "stuck" */}
          <div className="menu-wrapper">
            <PsoLogo />
          </div>

          <Search style={{ width: "40rem" }} />
          <div className="function-wrapper">
            <NavIcons style={{ marginLeft: "2.5rem" }} />

            {PcomLoggedIn || SmsLoggedIn ? (
              <NavAccountDropdown />
            ) : (
              <PrimaryButtonMotion
                title="Sign in"
                className="signin"
                iconArrow="true"
              />
            )}
          </div>
        </nav>

      </div>
    </div>
  );
}
