/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

/**styles */

import "../../style/pcom-reskin/TopStrapNav.scss";

export default function TopStrapNav({collegeActive}) {

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  return (
    <div id="TopStrapNav">
      <ul className="lines-of-business-nav">
        <li><a href="#" onClick={stopIt}>For School</a></li>
        <li><Link to={`${process.env.PUBLIC_URL}/higher-education`} className={collegeActive == 'true' ? 'active' : ''}>For College</Link></li>
        <li><a href="#" onClick={stopIt}>For Work</a></li>
        <li><a href="#" onClick={stopIt}>Explore Pearson</a></li>
      </ul>

      <div class="locale-selection section">
        <div>
          <button class="locale-selection__button selected_en-US" aria-haspopup="true" aria-label="Locale Selection. Currently selected: United States" aria-expanded="false" aria-controls="locale-selection-dropdown">United States</button>
          <div class="locale-selection__dropdown" aria-describedby="locale-selection-description" id="locale-selection-dropdown" style={{'display': 'none'}}>
            <ul role="menu" aria-label="Locale Selection">
              <li role="none" class="selected"><a role="menuitem" lang="en-US" hreflang="en-US" href="https://www.pearson.com/en-us.html" aria-selected="true" data-text="United States">United States</a></li>
              <li role="none"><a role="menuitem" lang="en-CA" hreflang="en-CA" href="https://www.pearson.com/en-ca.html" data-text="Canada">Canada</a></li>
              <li role="none"><a role="menuitem" lang="en-GB" hreflang="en-GB" href="https://www.pearson.com/en-gb.html" data-text="United Kingdom">United Kingdom</a></li>
              <li role="none"><a role="menuitem" lang="en" hreflang="en" href="https://www.pearson.com/en-us/location-selector.html" data-text="All countries">All countries</a></li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  );
}

