/** @format */

import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { radioSelect } from "../../recoil/recoilAtom";
import { useNavigate } from "react-router-dom";

import "../../style/pcom/_checkout-payment.scss";
/*icon*/
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked} from "react-icons/md";
/**components */
import IconButton from "./IconButton";

export default function ApplePay({
	subject,
	selectedCourse,
	platform,
	selectedPayment,
	handleRadioChange,
  disabled
}) {
	const [selectedOption, setSelectedOption] = useRecoilState(radioSelect);

	/**modal */
	const [showModal, setShowModal] = useState(false);
	const [showModalTwo, setShowModalTwo] = useState(false);
	const handleModalToggle = () => {
		setShowModal(!showModal);
	};

	const handleModalToggleTwo = () => {
		setShowModal(!showModal);
		setShowModalTwo(!showModalTwo);
	};

	/**price */
	const subtotal = parseFloat(selectedOption[selectedOption.length - 1]);
	const estimatedTax = (subtotal * 0.1).toFixed(2);
	const total = subtotal + parseFloat(estimatedTax);

	/**date placed time */
	const [initialTime] = useState(
		new Intl.DateTimeFormat("en-US", {
			month: "long",
			day: "2-digit",
			year: "numeric",
		}).format(new Date())
	);

	/**page route */
	const navigate = useNavigate();
	const handlePaymentClick = () => {
		if (selectedCourse === undefined) {
			console.debug(
				"ActionButton: No selectedCourse, just navigate normally"
			);
			navigate(`${process.env.PUBLIC_URL}/${subject}/order-confirmation`);
		} else {
			console.debug("ActionButton: HAS selectedCourse, so include it");
			navigate(
				`${process.env.PUBLIC_URL}/${subject}/order-confirmation`,
				{ state: { selectedCourse: selectedCourse } }
			);
		}
	};

	console.debug("ApplePay: Selected Course", selectedCourse);

	return (
		<>
			<div className={`payment-container ${disabled == 'true'? 'disabled' : ''}`}>
			<div className="radio-container">
					<input
						type="radio"
						id="apple-pay"
						name="payment"
						value="apple-pay"
						checked={selectedPayment === "apple-pay"}
						onChange={handleRadioChange}
            disabled={disabled == 'true' ? 'true' : 'fasle'}
					/>

					<label for="apple-pay">
						{selectedPayment === "apple-pay" ? (
							<MdOutlineRadioButtonChecked className="radio-icon-checked" />
						) : (
							<MdOutlineRadioButtonUnchecked className="radio-icon-unchecked" />
						)}

						<p>Checkout with Apple Pay</p>
						<img src={require(`../../assets/image/pdp/apple-pay.png`)} alt="apple-pay"/>
					</label>
				</div>
				<div className="description">
					<div className="paypal">
						<p className="legal-policy">
							By clicking “Continue with Apple Pay,” you agree to
							the Pearson+ <span>Subscription agreement</span> and
							acknowledge our <span> Privacy notice</span>. You
							will have two charges today: a one-time charge for
							your courseware access, and the first of a recurring
							$6.99 (plus tax) monthly charge for the Study & Exam
							Prep Pack subscription. You can cancel this purchase
							for a full refund within 14 days by contacting{" "}
							<span> Pearson Support</span>, and you can disable
							auto-renew on the subscription by going to{" "}
							<span> your account</span>.
						</p>

						<IconButton
							title="Continue with"
							icon="apple-logo"
							className="apple-button"
							onClick={handleModalToggle}
						/>
					</div>
				</div>
			</div>

			{showModal && (
				<div className="apple-modal">
					<div className="modal-content">
						<div className="header">
							<img
								src={require(`../../assets/image/pcom/apple_pay_logo@2x.png`)}
								alt="Apple Pay"
							/>
							<button onClick={handleModalToggle}>Cancel</button>
						</div>

						<div className="body">
							<div className="group card-info">
								<img
									src={require(`../../assets/image/pcom/card-placeholder@2x.png`)}
									alt=""
								/>
								<div>
									<h3 className="headline">Visa</h3>
									<p className="regular-para">….1234</p>
									<p className="regular-para single-line">
										683 Jefferson Street, Tiburon, CA 94123
									</p>
								</div>
							</div>

							<div className="group bill-details">
								<div className="bill-details-header">
									<img
										src={require(`../../assets/image/pcom/biller-logo@2x.png`)}
										alt=""
										className="biller-logo"
									/>
									<div>
										<h3 className="regular-para">
											Billing Details
										</h3>
										<p className="headline bill-company">
											Pearson
										</p>
									</div>
									<button>
										<img
											src={require(`../../assets/image/pcom/info@2x.png`)}
											alt="Information"
											className="icon-info"
										/>
									</button>
								</div>

								<hr />

								<div className="note">
									<p>
										You will have{" "}
										{selectedOption[3] === "bundle" ? (
											<>two charges</>
										) : (
											<>one charge</>
										)}{" "}
										today:
									</p>
									<ul>
										<li>
											A one-time charge of $
											{selectedOption[0]} (plus tax) for
											your {platform} course access
										</li>

										{selectedOption[3] === "bundle" ? (
											<li>
												The first of a recurring monthly
												charge of ${selectedOption[1]}{" "}
												(plus tax) for the Study & Exam
												Prep Pack subscription
											</li>
										) : null}
									</ul>
								</div>

								<hr />

								<div className="item">
									<p className="regular-para">
										<strong>
											{platform}
											{selectedOption[4] ===
											"Up to 18 weeks" ? (
												<>(18 week access)</>
											) : (
												<>(24 months access)</>
											)}
										</strong>
										<span>
											Starting{" "}
											{initialTime
												.toLocaleString()
												.replace("2024, ", "2024 ")}
										</span>
									</p>
									<p className="regular-para price">
										<span>{selectedOption[0]} US$</span>
										<span>0 months</span>
									</p>
								</div>

								<hr />

								{selectedOption[3] === "bundle" ? (
									<>
										<div className="item">
											<p className="regular-para">
												<strong>
													Study & Exam Prep Pack
													subscription
												</strong>
												<span>
													Starting{" "}
													{initialTime
														.toLocaleString()
														.replace(
															"2024, ",
															"2024 "
														)}
												</span>
											</p>
											<p className="regular-para price">
												<span>
													{selectedOption[1]} US$
												</span>
												<span>per month</span>
											</p>
										</div>
										<hr />
									</>
								) : null}
							</div>

							<div className="group contact">
								<img
									src={require(`../../assets/image/pcom/contact@2x.png`)}
									alt=""
									className="icon-contact"
								/>
								<div>
									<h3 className="regular-para">Contact</h3>
									<p className="headline">(123) 456-7890</p>
								</div>
								<button>
									<img
										src={require(`../../assets/image/pcom/edit@2x.png`)}
										alt="Edit"
										className="icon-edit"
									/>
								</button>
							</div>
						</div>

						<div className="pay-total">
							<div className="who">
								<p>Pay Pearson</p>
								<button>
									<img
										src={require(`../../assets/image/pcom/info@2x.png`)}
										alt="Information"
										className="icon-info"
									/>
								</button>
							</div>

							<p className="big-price">{total.toFixed(2)} US$</p>
						</div>

						<button
							className="touchid"
							onClick={handleModalToggleTwo}
						>
							<img
								src={require(`../../assets/image/pcom/touch-indicator@2x.png`)}
								alt="TouchID"
							/>
							Pay with TouchID
						</button>
					</div>
				</div>
			)}

			{showModalTwo && (
				<div className="apple-modal">
					<div className="modal-content">
						<div className="header">
							<img
								src={require(`../../assets/image/pcom/apple_pay_logo@2x.png`)}
								alt="Apple Pay"
							/>
						</div>

						<div className="payee">
							<img
								src={require(`../../assets/image/pcom/biller-logo-square@2x.png`)}
								alt=""
								className="biller-logo"
							/>
							<p className="payee-name">Pearson</p>
						</div>

						<div className="body">
							<div className="group bill-details">
								<div className="item">
									<p className="regular-para">
										<strong>
											{platform}
											{selectedOption[4] ===
											"Up to 18 weeks" ? (
												<>(18 week access)</>
											) : (
												<>(24 months access)</>
											)}
										</strong>
										<span>
											Starting{" "}
											{initialTime
												.toLocaleString()
												.replace("2024, ", "2024 ")}
										</span>
									</p>
									<p className="regular-para price">
										<span>{selectedOption[0]} US$</span>
										<span>0 months</span>
									</p>
								</div>

								<hr />

								{selectedOption[3] === "bundle" ? (
									<>
										<div className="item">
											<p className="regular-para">
												<strong>
													Study & Exam Prep Pack
													subscription
												</strong>
												<span>
													Starting{" "}
													{initialTime
														.toLocaleString()
														.replace(
															"2024, ",
															"2024 "
														)}
												</span>
											</p>
											<p className="regular-para price">
												<span>
													{selectedOption[1]} US$
												</span>
												<span>per month</span>
											</p>
										</div>
									</>
								) : null}
							</div>

							<div className="group">
								<h3 className="headline">
									Payment Authorisation
								</h3>
								<p className="regular-para">
									Your payment method wil be stored by the
									merchant and charged automatically.
								</p>
							</div>

							<div className="group note">
								<h3 className="headline">Billing Agreement</h3>
								<div className="note">
									<p>
										You will have{" "}
										{selectedOption[3] === "bundle" ? (
											<>two charges</>
										) : (
											<>one charge</>
										)}{" "}
										today:
									</p>
									<ul>
										<li>
											A one-time charge of $
											{selectedOption[0]} (plus tax) for
											your {platform} course access
										</li>

										{selectedOption[3] === "bundle" ? (
											<li>
												The first of a recurring monthly
												charge of ${selectedOption[1]}{" "}
												(plus tax) for the Study & Exam
												Prep Pack subscription
											</li>
										) : null}
									</ul>
								</div>
							</div>
						</div>

						<div className="done">
							<button onClick={handlePaymentClick}>Done</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
