import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { PcomLoginState } from "../../recoil/recoilAtom";
import { FaChevronDown } from "react-icons/fa6";

import "../../style/pcom/_nav.scss";

export default function NavAccountDropdown() {
	const { subject } = useParams();
	const [DropdownOpen, setDropdownOpen] = useState(false);
	const [loggedIn, setLoggedIn] = useRecoilState(PcomLoginState);

	const DropdownList = [
		{ name: "Get Verified", to: "/" },
		{ name: "Account details", to: "/" },
		{ name: "Order history", to: "/" },
		{ name: "Address book", to: "/" },
		{ name: "Wallet", to: "/" },
		{ name: "Preferences", to: "/" },
		{ name: "Change password", to: "/" },	
		{ name: "My learning", to: "/" },
		{ name: "Track Order", to: "/" },
	];
	const handleDropdown = () => {
		setDropdownOpen(!DropdownOpen);
	};
	const handleSignOut = () => {
		setLoggedIn(false);
		setDropdownOpen(false);
	};
	return (
		<>
			<div className="account-button-wrapper" onClick={handleDropdown}>
				<button
					type="button"
					className={`account-button ${DropdownOpen ? "active" : ""}`}
				>
					SD
				</button>
				<FaChevronDown
					className={`down-arrow ${DropdownOpen ? "active" : ""}`}
				/>
			</div>
			{DropdownOpen && (
				<ul className="dropdown-menu active">
					<div className="triangle"></div>
					<li className="user">
						<Link to={process.env.PUBLIC_URL + "/"}>
							Hi, Student
						</Link>
					</li>
					{DropdownList.map((item, index) => (
						<li key={index}>
							<Link to={process.env.PUBLIC_URL + item.to}>
								{item.name}
							</Link>
						</li>
					))}
					<li className="sign-out" onClick={handleSignOut}>
					<Link to={`${process.env.PUBLIC_URL}/${subject}/pcom-signin`}>Sign out</Link>
					</li>
				</ul>
			)}
		</>
	);
}
