/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function IconButton({
	title,
	style,
	icon,
	route,
	className,
	onClick,
	selectedCourse
}) {
	const navigate = useNavigate();

	const handleClick = () => {
		if (className === "apple-button") {
			if (onClick) {
				onClick();
			}
		} else if (className === "paypal-button") {
			if (selectedCourse === undefined) {
				console.debug('IconButton: No selectedCourse, just navigate normally');
				navigate(route);
			} else {
				console.debug('IconButton: HAS selectedCourse, so include it');
				navigate(route, {state: {selectedCourse: selectedCourse}});
			}
		}
	};

	if (className === "apple-button") {
		return (
			<AppleButtonContainer style={style} onClick={handleClick}>
				{title}
				<img
					src={require(`../../assets/image/pdp/${icon}.png`)}
					alt={title}
				/>
			</AppleButtonContainer>
		);
	}

	if (className === "paypal-button") {
		return (
			<PaypalButtonContainer style={style} onClick={handleClick}>
				{title}
				<img
					src={require(`../../assets/image/pdp/${icon}.png`)}
					alt={title}
				/>
			</PaypalButtonContainer>
		);
	}
}

const AppleButtonContainer = styled.button`
	width: 370px;
	margin: 0 auto;
	background: var(--ui-elements-background-dark, #151515);
	color: #fefefe;
	border-radius: 40px;
	border: 2px solid var(--ui-elements-background-dark, #151515);
	font-size: 1rem;
	cursor: pointer;
	font-family: var(--typefamily--primary-stack);
	font-weight: 700;
	padding: 10px;
	display: flex;
	justify-content: center;
	img {
		margin: 0 5px;
	}
	&:focus {
		border: 3px solid var(--interactive-focus-field, #2192fb);
		background-color: var(--interactive-secondary-active, #505759);
		color: var(--text-primary-inverse, #fefefe);
		transform: scale(1.03);
		transition: all 0.2s ease-in-out;
	}
	&:hover {
		background-color: var(--interactive-secondary-active, #505759);
		color: var(--text-primary-inverse, #fefefe);
		border: 2px solid var(--interactive-secondary-active, #505759);
	}
`;

const PaypalButtonContainer = styled.button`
	width: 370px;
	margin: 0 auto;
	background-color: var(--color--interactive-action);
	color: var(--color--text-primary);
	border-radius: 40px;
	border: 2px solid var(--color--interactive-action);
	font-size: 1rem;
	cursor: pointer;
	font-family: var(--typefamily--primary-stack);
	font-weight: 700;
	padding: 10px;
	display: flex;
	justify-content: center;
	img {
		margin: 0 5px;
	}

	&:hover {
		background-color: var(--color--interactive-action-hover);
		color: var(--color--text-primary-inverse);
		border: 2px solid var(--color--interactive-action-hover);
		transform: scale(1.03);
		transition: all 0.2s ease-in-out;
	}
	&:focus {
		border: 3px solid var(--interactive-focus-field, #2192fb);
		background-color: var(--color--tertiary-action);
		color: #151515;
	}
`;
