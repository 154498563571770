/** @format */

import React from "react";
import "../../style/pcom/_checkout-payment.scss";

/*icon*/
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked,} from "react-icons/md";
/**components */
import IconButton from "./IconButton";

export default function PayPal({ subject, selectedCourse , selectedPayment, handleRadioChange}) {

	console.debug("PayPal: Selected Course", selectedCourse);

	return (
		<>
			<div className="payment-container">
				<div className="radio-container">
					<input
						type="radio"
						id="paypal"
						name="payment"
						value="paypal"
						checked={selectedPayment === 'paypal'}
						onChange={handleRadioChange}
					/>

					<label for="paypal">
						{selectedPayment === 'paypal' ? (
							<MdOutlineRadioButtonChecked className="radio-icon-checked" />
						) : (
							<MdOutlineRadioButtonUnchecked className="radio-icon-unchecked" />
						)}

						<p>Checkout with PayPal</p>
						<img src={require(`../../assets/image/pdp/paypal.png`)} alt="paypal"/>
					</label>
				</div>

				<div className="description">
					<div className="paypal">
						<p className="legal-policy">
							By clicking “Continue with PayPal,” you agree to the
							Pearson+ <span>Subscription agreement</span> and
							acknowledge our <span> Privacy notice</span>. You
							will have two charges today: a one-time charge for
							your courseware access, and the first of a recurring
							$6.99 (plus tax) monthly charge for the Study & Exam
							Prep Pack subscription. You can cancel this purchase
							for a full refund within 14 days by contacting{" "}
							<span> Pearson Support</span>, and you can disable
							auto-renew on the subscription by going to{" "}
							<span> your account</span>.
						</p>

						<IconButton
							title="Continue with"
							icon="paypal-logo"
							className="paypal-button"
							route={`${process.env.PUBLIC_URL}/${subject}/order-confirmation`}
							selectedCourse={selectedCourse}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
