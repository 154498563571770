/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Breadcrumb(props) {
	const [steps, setSteps] = useState(
		props.steps || [
			{ label: "Home", url: "/#" },
			{ label: "Step 2", url: "/#" },
			{ label: "Step 3", url: "/#" },
			{ label: "Step 4", url: "/#" },
			{ label: "Step 5", url: "/#" },
			{ label: "Step 6", url: "/#" },
			{ label: "Step 7", url: "/#" },
		]
	);

	return (
		<BreadcrumbContainer>
			{steps.map((step, index) => (
				<Step key={step.label}>
					{index > 0 && (
						<span className="breadcrumb-separator">&#8250;</span>
					)}
					{index < steps.length - 1 ? (
						<Link href={step.url}>{step.label}</Link>
					) : (
						<p>{step.label}</p>
					)}
				</Step>
			))}
		</BreadcrumbContainer>
	);
}

const BreadcrumbContainer = styled.div`
	display: flex;
	flex-direction row;
	background-color: var(--color--ui-background-dark);
  // background-color: transparent;
  padding: 6px 20px;
`;

const Step = styled.div`
	font-family: var(--typefamily--primary-stack);
	color: #919191;

	a {
		color: #919191;
		font-size: 0.75rem;
		padding: 0 10px;
	}

	p {
		color: var(--color--text-tertiary-inverse);
		font-size: 0.75rem;
		padding: 0 10px;
		display: inline;
		font-weight: 645;
	}

	&.breadcrumb-separator {
	}
`;
