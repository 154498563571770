import React from "react";
import { useNavigate } from "react-router-dom";
import { LuExternalLink } from "react-icons/lu";
import "../../style/pcom/_buttons.scss";

export default function NonBundleButton() {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(process.env.PUBLIC_URL + '/get-registered');
	};

	return (
		<button
			style={{ marginTop: "15px", minWidth: "167px" }}
			onClick={handleClick}
			className="action-button-container"
		>
			Buy now
			<LuExternalLink style={{marginLeft:'10px'}}/>
		</button>
	);
}
