import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import {radioSelect,openAccordion,closeAccordion,} from "../../recoil/recoilAtom";
/**css */
import "../../style/pcom/_orderConfirmation.scss";
/*data*/
import bookData from "../../assets/data/book.json";
import CourseData from '../../assets/data/courses.json';
/*icon*/
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import needHelpIcon from '../../assets/image/pdp/need-help-icon.svg';
/**components */
import TotalPrice from "../../components/checkout/TotalPrice";
import TeriaryButton from "../../components/global/TertiaryButton";
import SecondaryButton from "../../components/global/SecondaryButton";
import Nav from "../../components/pcom-reskin/Nav";
import Footer from "../../components/global/Footer";

export default function OrderConfirmation() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const location = useLocation();
  const { subject } = useParams();

  let selectedCourse = undefined;
  if (location.state) {
    selectedCourse = location.state.selectedCourse;
  }

  const selectedBook = bookData.find((book) => book.subject === subject);
  const matchedCourse = CourseData.find((course) => course.productSubject === subject);
  const { title, bookCover, platform, shortTitle } = selectedBook;
  const [selectedOption, setSelectedOption] = useRecoilState(radioSelect);
  /**course detail */
  const [detailOpen, setDetailOpen] = useRecoilState(openAccordion);
  const toggleDetail = () => {
    setDetailOpen(!detailOpen);
  };
  /**bundle subcription detail */
  const [isSubscriptionOpen, setIsSubscriptionOpen] =
    useRecoilState(closeAccordion);

  const toggleSubscription = () => {
    setIsSubscriptionOpen(!isSubscriptionOpen);
  };

  /**total price */
  const subtotal = parseFloat(selectedOption[selectedOption.length - 1]);
  const estimatedTax = (subtotal * 0.1).toFixed(2);
  const total = subtotal + parseFloat(estimatedTax);

  /**date placed time */
  const [initialTime] = useState(
    new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
      .format(new Date())
  );

  console.debug('OrderConfirmation: Selected Course', selectedCourse);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  return (
    <>
      <Nav />

      <div className="PCOM">
        <div className="order-confirmation">
          <main>
            <div className="page-top">
              <h1 className="page-title">Order confirmation</h1>
              <h2 className="sub-title">
                Thank you for your purchase
              </h2>
              <p className="description">
                Your order number is <span>10000002692053</span>. A
                copy of your order details has been sent to
                <span> charlie.frost@icloud.com.</span>
              </p>
            </div>

            <section className="order-body">
              <h3>Access your digital products</h3>
              <p>
                Connect to all your products anytime from your <span>My learning page</span>.
              </p>
              <div className="order">
                <img
                  src={require(`../../assets${bookCover}`)}
                  alt={title}
                  className="book-cover"
                />

                <div className="text">
                  <h3>
                    {platform} {subject} with Pearson eText for {shortTitle}
                  </h3>
                  {/*<TeriaryButton   route={`${process.env.PUBLIC_URL}/${subject}/mastering-home`}  title="Access this course" />*/}
                  <button className="tertiary-button-container" onClick={stopIt}>Access this course</button>
                </div>
              </div>

              {selectedOption[3] === "bundle" ? (
                <div className="order">
                  <img
                    src={require('../../assets/image/channels-promo.png')}
                    className="channel"
                    alt={title}
                  />

                  <div className="text">
                    <h3>Study & Exam Prep Pack</h3>
                    <button className="tertiary-button-container" onClick={stopIt}>Start studying on Pearson+</button>
                    {/*{shortTitle === "Campbell Biology" ? (
                      <Link to="https://pearsonct.design/apps/auth-home-2024-phase2/course/0?firstView=true&step=welcome" className="tertiary-button-container" target="_blank">Start studying on Pearson+</Link>
                    ) : (
                      <TeriaryButton title="Start studying on Pearson+" />
                    ) }*/}
                  </div>
                </div>
              ) : null}

              <div className="order-summary">
                <h3>Order Summary</h3>
                <div className="order-content">
                  <div className="order-detail">
                    <h4>Order number</h4>
                    <p>10000002692053</p>
                  </div>

                  <div className="order-detail">
                    <h4>Date placed</h4>
                    <p>{initialTime.toLocaleString().replace('2024, ','2024 ')}</p>
                  </div>

                  <div className="order-detail">
                    <h4>Total</h4>
                    <p>${total.toFixed(2)}</p>
                  </div>
                </div>
              </div>

              <div className="billing">
                <div className="billing-content">
                  <h4>Bill to</h4>
                  <p>
                    Charlie Frost <br/>683 Jefferson Street, <br/> Tiburon,
                    California <br/> 94123 <br/> United States
                  </p>
                  <p></p>
                </div>

                <div className="billing-content">
                  <h4>Payment method</h4>
                  <p>Credit Card</p>
                </div>
              </div>
            </section>

            <section className="order-body">
              <div className="order">
                <img
                  src={require(`../../assets${bookCover}`)}
                  alt={title}
                  className="book-cover"
                />

                <div className="text">
                <h3>{platform} {subject} with Pearson eText for {shortTitle}</h3>
                  {selectedOption[4] === 'Up to 18 weeks' ? <p>Single-term access (up to 18 weeks)</p> : <p>Multi-term access (up to 24 months)</p>}
                  <div className="row" style={{marginTop: '13px'}}>
                    <p>ISBN-13: {selectedOption[2].substring(0,13)}</p>
                    <p>${selectedOption[0]}</p>
                    <p>Qty : 1</p>
                    <p className="price">${selectedOption[0]}</p>
                  </div>
                </div>
              </div>

              <div className="course-detail-container">
                <div className="header" onClick={toggleDetail}>
                  <h6>Course details</h6>

                  {detailOpen ? (
                    <IoIosArrowUp className="arrow" size={18} />
                  ) : (
                    <IoIosArrowDown className="arrow" size={18}/>
                  )}
                </div>
                {detailOpen && (
                  <div className="course-detail-info">
                    <p>Course: {matchedCourse.title}</p>
                    <p>Course ID: {matchedCourse.id}</p>
                    <p>Instructor: {matchedCourse.instructor}</p>
                    <p>End Date: {matchedCourse.endDate}</p>
                  </div>
                )}
              </div>

              {selectedOption[3] === "bundle" ? (
                <div className="bundle-pack-wrapper">
                  <div className="bundle-pack">
                    <img
                      src={require('../../assets/image/channels-promo.png')}
                      alt={title}
                    />
                    <div className="text">
                      <h3>Study & Exam Prep Pack</h3>
                      <p>Monthly subscription to Pearson+ study videos and tools.</p>
                      <div className="row" style={{marginTop: '13px'}}>
                        <p><span style={{visibility: 'hidden'}}>ISBN-13: 0000000000000</span></p>
                        <p>${selectedOption[1]}</p>
                        <p>Qty : 1</p>
                        <p className="price">${selectedOption[1]}</p>
                      </div>
                    </div>
                  </div>

                  <div className="subscription">
                    <div
                      className="header"
                      onClick={toggleSubscription}
                    >
                      <h6>Subscription details</h6>

                      {isSubscriptionOpen ? (
                        <IoIosArrowDown
                          className="arrow"
                          size={18}
                        />
                      ) : (
                        <IoIosArrowUp
                          className="arrow"
                          size={18}
                        />
                      )}
                    </div>
                    {!isSubscriptionOpen && (
                      <div className="subscription-content">
                      <p>By placing your order for a Study & Exam Prep Pack subscription, you agree to the Pearson+ <span> Subscription agreement </span> and acknowledge our <span> Privacy notice</span>. <b>You authorize automatic monthly payments of $6.99 (plus tax),</b> and you may cancel this purchase for a full refund within 14 days by contacting <span>Pearson Support</span>. You can disable auto-renewal of the subscription at any time by going to <span>your account</span>.
                        </p>

                      <p><i>You will have two charges today: a one-time charge for your courseware access, and the first of a recurring $6.99 (plus tax) monthly charge for the Study & Exam Prep Pack subscription.</i></p>
                       </div>
                    )}
                  </div>
                </div>
              ) : null}
            </section>

            <section className="price-confirmation">
              <div className="position-right">
                <TotalPrice
                  selectedOption={selectedOption}
                  style={{
                    backgroundColor: "transparent",
                  }}
                />
                <SecondaryButton
                  title="Continue shopping"
                  style={{ padding: "12px 0" }}
                  route={`${process.env.PUBLIC_URL}/`}
                />
              </div>
            </section>
          </main>
        </div>

        <section>
          <p className="need-help-footer">
            <img
              src={needHelpIcon}
              alt="help-icon"
            />
            <span>Need help?</span>
            <span className="fake-link">Get in touch</span>
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
}
