/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";

import "../../style/pcom/_buttons.scss";

export default function ActionButton({ title, style, route, selectedCourse }) {
	const navigate = useNavigate();
	const handleClick = () => {
		if (selectedCourse === undefined) {
			// console.debug('ActionButton: No selectedCourse, just navigate normally');
			navigate(route);
		} else {
			// console.debug('ActionButton: HAS selectedCourse, so include it');
			navigate(route, {state: {selectedCourse: selectedCourse}});
		}
	};

	// console.debug('ActionButton: Selected Course', selectedCourse);

	return (
		<button
			style={style}
			onClick={handleClick}
			className="action-button-container"
		>
			{title}
		</button>
	);
}
