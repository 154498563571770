/** @format */

import React from 'react';

export default function Footer() {
	return (
    <>
      <div className='PCOM-GANKED'>
        <footer data-testid="footer">
          <span>
            <div class="root responsivegrid">
              <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                <section class="column-control layout-default bgcolor--default clipping-default gradients-default default-animation default-gutters has-padding-top--none has-padding-bottom--none default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow aem-GridColumn he-footer-global aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <section class="column-control layout-default bgcolor--default clipping-default gradients-default default-animation default-gutters has-padding-top--30 has-padding-bottom--30 default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow section c-footer__main c-footer__bottom">
                          <div class="row">
                            <div class="col-12">
                              <div class="link-list default section c-footer__link-list">
                                <div>
                                  <ul id="mn-root-2107762321">
                                    <li>
                                      <a href="https://pearson-stg2.pearson.com/en-us/legal-information/terms-of-use.html">Terms of use
                                      </a>
                                    </li>
                                    <li>
                                      <a href="https://pearson-stg2.pearson.com/en-us/legal-information/privacy-policy.html">Privacy
                                      </a>
                                    </li>
                                    <li>
                                      <a href="https://pearson-stg2.pearson.com/en-us/legal-information/cookie-policy.html" aria-label="Cookie Settings" id="open-ot-preference-modal">Cookies
                                      </a>
                                    </li>
                                    <li>
                                      <a href="https://pearson-stg2.pearson.com/en-us/legal-information/cookie-policy.html">Do not sell or share my personal information
                                      </a>
                                    </li>
                                    <li>
                                      <a href="https://pearson-stg2.pearson.com/en-us/legal-information/accessibility.html">Accessibility
                                      </a>
                                    </li>
                                    <li>
                                      <a href="https://pearson-stg2.pearson.com/en-us/legal-information/patent-notice.html">Patent notice
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="text section c-footer__bottom-copyright">
                                <p>© 1996–2023 Pearson All rights reserved.</p>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </span>
        </footer>
      </div>
    </>
	);
}
