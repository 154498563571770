import React from 'react';
import { useNavigate } from "react-router-dom";
import "../../style/pcom/_buttons.scss";

export default function SecondaryButton({title, style, route}) {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(route);
	};

	return (
		<button
			style={style}
			onClick={handleClick}
			className="secondary-button-container"
		>
			{title}
		</button>
	);
}
