/** @format */

import React from "react";
import "../../style/pcom/_checkout-totalPrice.scss";

export default function TotalPrice({ selectedOption, style }) {
		/**price */
	const subtotal = parseFloat(selectedOption[selectedOption.length - 1]);
	const estimatedTax = (subtotal * 0.1).toFixed(2);
	const total = subtotal + parseFloat(estimatedTax);

	return (
		<div className="PriceContainer" style={style}>
			<div className="Row">
				<p className="Text">Subtotal</p>
				<p className="Text">${subtotal}</p>
			</div>

			<div className="Row">
				<p className="Text">Estimated Tax</p>
				<p className="Text">${estimatedTax}</p>
			</div>

			<div className="Total">
				<h3>Total</h3>
				<p>${total.toFixed(2)}</p>
			</div>
		</div>
	);
}
