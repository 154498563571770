/** @format */

import React, {useEffect} from "react";
import { useParams } from "react-router-dom";

import "../../style/pcom/_pdp.scss";

/**components */
import TopStrapNav from '../../components/pcom-reskin/TopStrapNav';
import Nav from "../../components/pcom-reskin/Nav";
import FooterCollege from '../../components/pcom-reskin/FooterCollege';

/**data */
import bookData from "../../assets/data/book.json";
import bookOverviewData from "../../assets/data/bookOverview.json";

/**component */
import Breadcrumb from "../../components/global/Breadcrumb";
import PriceTab from "../../components/pdp/PriceTab";
import ToggleSwitch from "../../components/pdp/ToggleSwitch";
import DetailOverviewTab from "../../components/pdp/DetailOverviewTab";

import needHelpIcon from "../../assets/image/need-help-icon.svg"

export default function Pdp() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const { subject } = useParams();
  const selectedBook = bookData.find((book) => book.subject === subject);

  const { title, shortTitle, publish, author, bookCover, tab, breadcrumb } = selectedBook;
  const TocContent = bookOverviewData.find(
    (book) => book.bookName === subject
  );

  return (
    <>
      <TopStrapNav collegeActive='true' />
      <Nav isSticky='true' />

      <div className="PCOM">
        <Breadcrumb steps={breadcrumb} />
        <div className="background-wrapper">
          <div className="main">
            <section className="book-cover">
              <img
                src={require(`../../assets${bookCover}`)}
                alt={title}
              />
            </section>

            <section className="book-info-container">
              <ToggleSwitch />
              <div className="book-info">
                <h1 className="title">{title}</h1>
                <p>{publish}</p>
                <p>{author.join("|")}</p>
              </div>

              <PriceTab tabInfo={tab} defaultTab={2} title={shortTitle} subject={subject} />
              {/* {subject === "chemistry" ? (
                <PriceTab tabInfo={tab} defaultTab={0} title={shortTitle} />
              ) : (
                <PriceTab tabInfo={tab} defaultTab={2}  title={shortTitle} />
              )} */}
            </section>
          </div>

          <section>
            <DetailOverviewTab content={TocContent} />
            <p className="need-help">
              <img src={needHelpIcon} alt="" />
              <span>Need help?</span>
              <span className="fake-link">Get in touch</span>
            </p>
          </section>
        </div>
      </div>
      <div className="PCOM-GANKED">
        <FooterCollege />
      </div>
    </>
  );
}
