/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoGrid } from "react-icons/io5";
import { CiGrid2H } from "react-icons/ci";

/**components */
import TopStrapNav from '../../components/pcom-reskin/TopStrapNav';
import Nav from "../../components/pcom-reskin/Nav";
import FooterCollege from '../../components/pcom-reskin/FooterCollege';
import Filters from "../../components/pdp/Filters";
import Select from "../../components/global/Select";

/**data */
import bookData from "../../assets/data/book.json";
/** CSS **/
import "../../style/pcom/_isbnSearch.scss";

export default function ISBNSearch() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <TopStrapNav collegeActive='true' />
      <Nav isSticky='true' />

      <div className="PCOM">
        <header className="curved">
          <h1 className="title">Showing results </h1>
        </header>

        <main className="isbn-search">
          <div className="select-wrapper">
            <CiGrid2H className="grid-icon"/>
              <IoGrid className="grid-icon active"/>
            <Select  defaultValue="20" options={["20", "50", "100"]} />
            <Select defaultValue="Relevance" options={["Relevance", "Price (low to hight)", "Price (hight to low)", "A-Z","New to old" ,"Old to new"]} />
          </div>
          <div className="main-wrapper">
            <section className="left-column">
              <Filters />
            </section>

            <section className="right-column">
              {bookData.filter((book) => book.subject !== "chemistry").map((book, index) => (
                <div key={index} className="book">
                  <Link
                    to={`${process.env.PUBLIC_URL}/${book.subject}`}
                  >
                    <img
                      src={require(`../../assets${book.bookCover}`)}
                      alt={book.title}
                    />

                    <div className="book-info">
                      {book.title.includes(",") && (
                        <>
                          <h3>{book.title.split(",")[0]}</h3>
                          <div className="edition-author">
                            <p>{book.title.split(",")[1]}</p>
                            <p className="author">{book.author[0]}</p>
                          </div>
                        </>
                      )}
                      <p className="price">
                        3 options {book.tab.eTextbook.price}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </section>
          </div>
        </main>
      </div>
      <div className="PCOM-GANKED">
        <FooterCollege />
      </div>
    </>
  );
}
