/** @format */

import React from 'react';

import "../../style/pcom/_cart-orderHelp.scss";

export default function OrderHelp() {
	return (
		<div className="order-help">

			<div>
				<h2>Free shipping</h2>
				<p>We offer free shipping on all US orders. No matter what.</p>
			</div>

			<div className="divider"></div>

			<div>
				<h2>Free and easy returns</h2>
				<p>Wrong product? No problem. Send it back to us within 30 days.</p>
			</div>

			<div className="divider"></div>

			<div>
				<h2>Need help?</h2>
				<p>Call us at <strong>+1 (800) 624-0023</strong> Monday to Friday, 8 AM to 8 PM</p>
			</div>

		</div>
	);
}
