/** @format */

import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

/**styles */
import "../style/pcom-reskin/_ganked.scss";


export default function Buttons() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  return (
    <div style={{minHeight: '100vh', backgroundColor: '#444', padding: '2rem'}}>

      <h1 style={{color: 'white'}}>Chart example</h1>
      <ul style={{color: 'white'}}>
        <li>🚫 Animation and interactivity doesn’t copy over from generator. Boo.</li>
        <li>✅ Typeface inherited from parent element (which is driven vai tokens)</li>
        <li>✅ Colors driven using tokens</li>
      </ul>

      <div style={{fontFamily: 'var(--typefamily-primary)'}}>
        <svg version="1.1" style={{fontSize: '12px'}} xmlns="http://www.w3.org/2000/svg" width="400" height="300" viewBox="0 0 400 300" aria-hidden="false" aria-label="Interactive chart">

          <defs aria-hidden="true">
            <clipPath >
              <rect x="0" y="0" width="380" height="201" fill="none"></rect>
            </clipPath>
          </defs>
          <rect fill="#ffffff" x="0" y="0" width="400" height="300" rx="0" ry="0" aria-hidden="true"></rect>
          <rect fill="none" x="10" y="46" width="380" height="201" aria-hidden="true"></rect>
          <g data-z-index="0" aria-hidden="true"></g>
          <rect fill="none" data-z-index="1" x="10" y="46" width="380" height="201" aria-hidden="true"></rect>
          <g data-z-index="3" aria-hidden="false">
            <g data-z-index="0.1" opacity="1" transform="translate(10,46) scale(1 1)" aria-hidden="false" clip-path="none">
              <path fill="var(--color--accent-01-primary)" d="M 189.98484671364508 26.100001543159195 A 74.4 74.4 0 0 1 203.74385349152575 173.61953563312255 L 198.93350476949175 148.02769816152966 A 48.36 48.36 0 0 0 189.9901503638693 52.140001003053484 Z" transform="translate(0,0)" stroke="#ffffff" stroke-width="1" opacity="1" stroke-linejoin="round" tabindex="-1" role="img" aria-label="Item 1, 80." style={{outline: 'none'}}></path>
              <path fill="var(--color--accent-02-primary)" d="M 203.67072709615306 173.63324292455866 A 74.4 74.4 0 0 1 123.38024447401972 67.37647099630507 L 146.6971589081128 78.96970614759829 A 48.36 48.36 0 0 0 198.88597261249947 148.03660790096313 Z" transform="translate(0,0)" stroke="#ffffff" stroke-width="1" opacity="1" stroke-linejoin="round" tabindex="-1" role="img" aria-label="Item 2, 60." style={{outline: 'none'}}></path>
              <path fill="var(--color--accent-03-primary)" d="M 123.41340130737781 67.30986781364551 A 74.4 74.4 0 0 1 189.89665989260746 26.10007176870259 L 189.93282893019486 52.14004664965669 A 48.36 48.36 0 0 0 146.71871084979557 78.92641407886958 Z" transform="translate(0,0)" stroke="#ffffff" stroke-width="1" opacity="1" stroke-linejoin="round" tabindex="-1" role="img" aria-label="Item 3, 30." style={{outline: 'none'}}></path>
            </g>
            <g data-z-index="0.1" opacity="1" transform="translate(10,46) scale(1 1)" aria-hidden="true" clip-path="none"></g>
          </g>
          <text x="200" text-anchor="middle" data-z-index="4" style={{color: 'var(--color--text-primary)', fontSize: '18px', fontWeight: '600', fill: 'var(--color--text-primary)', fontFamily: 'inherit'}} y="24" aria-hidden="true">Chart title</text>
          <text x="200" text-anchor="middle" data-z-index="4" style={{color: 'var(--color--text-secondary)', fill: 'var(--color--text-secondary)', fontFamily: 'inherit'}} y="45" aria-hidden="true">Chart subtitle</text>
          <g data-z-index="6" opacity="1" transform="translate(10,46) scale(1 1)" aria-hidden="true">
            <path fill="none" stroke="var(--color--accent-01-primary)" stroke-width="1" d="M 298.9546480052016 90.86718327203124 C 293.9546480052016 90.86718327203124 276.0314328318909 92.52801374237069 270.05702777412074 93.0816238991505 L 264.08262271635056 93.63523405593031"></path>
            <path fill="none" stroke="var(--color--accent-02-primary)" stroke-width="1" d="M 101.68700147194302 163.4150560379944 C 106.68700147194302 163.4150560379944 121.05131156298734 152.56763258316775 125.83941492666877 148.95182476489222 L 130.6275182903502 145.3360169466167"></path>
            <path fill="none" stroke="var(--color--accent-03-primary)" stroke-width="1" d="M 130.04048219560403 11.737331029828297 C 135.04048219560403 11.737331029828297 144.51626112739643 27.04123947296135 147.67485410466057 32.14254228733903 L 150.8334470819247 37.243845101716715"></path>
            <g data-z-index="1" transform="translate(304,81)">
              <text x="5" data-z-index="1" y="16" style={{color: 'var(--color--text-tertiary)', fontSize: '11px', fontWeight: 'bold', fill: 'var(--color--text-tertiary)', fontFamily: 'inherit'}}>
                <tspan style={{fontWeight: '500'}}>Item 1</tspan>
                : 47.1 %
              </text>
            </g>
            <g data-z-index="1" transform="translate(18,153)">
              <text x="5" data-z-index="1" y="16" style={{color: 'var(--color--text-tertiary)', fontSize: '11px', fontWeight: 'bold', fill: 'var(--color--text-tertiary)', fontFamily: 'inherit'}}>
                <tspan style={{fontWeight: '500'}}>Item 2</tspan>
                : 35.3 %
              </text>
            </g>
            <g data-z-index="1" transform="translate(46,2)">
              <text x="5" data-z-index="1" y="16" style={{color: 'var(--color--text-tertiary)', fontSize: '11px', fontWeight: 'bold', fill: 'var(--color--text-tertiary)', fontFamily: 'inherit'}}>
                <tspan style={{fontWeight: '500'}}>Item 3</tspan>
                : 17.6 %
              </text>
            </g>
          </g>
          <g data-z-index="7" aria-hidden="true" transform="translate(90,259)">
            <rect fill="none" rx="0" ry="0" x="0" y="0" width="220" height="26"></rect>
            <g data-z-index="1">
              <g>
                <g data-z-index="1" transform="translate(8,3)">
                  <text x="21" text-anchor="start" data-z-index="2" y="15" style={{color: 'var(--color--secondary)', fontSize: '12px', fontWeight: '500', fill: 'var(--color--secondary)', fontFamily: 'inherit'}}>Item 1</text>
                  <rect x="2" y="4" width="12" height="12" fill="var(--color--accent-01-primary)" rx="6" ry="6" data-z-index="3"></rect>
                </g>
                <g data-z-index="1" transform="translate(82.7734375,3)">
                  <text x="21" y="15" text-anchor="start" data-z-index="2" style={{color: 'var(--color--secondary)', fontSize: '12px', fontWeight: '500', fill: 'var(--color--secondary)', fontFamily: 'inherit'}}>Item 2</text>
                  <rect x="2" y="4" width="12" height="12" fill="var(--color--accent-02-primary)" rx="6" ry="6" data-z-index="3"></rect>
                </g>
                <g data-z-index="1" transform="translate(157.546875,3)">
                  <text x="21" y="15" text-anchor="start" data-z-index="2" style={{color: 'var(--color--secondary)', fontSize: '12px', fontWeight: '500', fill: 'var(--color--secondary)', fontFamily: 'inherit'}}>Item 3</text>
                  <rect x="2" y="4" width="12" height="12" fill="var(--color--accent-03-primary)" rx="6" ry="6" data-z-index="3"></rect>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>


    </div>
  );
}
