import { Routes, Route } from 'react-router-dom';
import Start from './page/Start';
import Home from './page/pcom-reskin/Home';
import College from './page/pcom-reskin/College';
import Students from './page/pcom-reskin/Students';
import Educators from './page/pcom-reskin/Educators';
import ISBNSearch from './page/pcom/ISBNSearch';
import Pdp from './page/pcom/Pdp';
import Cart from './page/pcom/Cart';
import PcomSignin from './page/pcom/PcomSignin';
import Checkout from './page/pcom/Checkout';
import OrderConfirmation from './page/pcom/OrderConfirmation';

// One-off tests
import Buttons from './page/Buttons';
import Charts from './page/Charts';

import "./style/pcom-reskin/reskin-globals.css";// Reskin Variables for global use
import './style/global-reset.css'; /* Global simple reset */
import './style/pcom/reset.css';
import './style/sms/reset.css';

/* Final style overrides */
import "./style/pcom-reskin/reskin-token-overrides.css";
import "./style/pcom-reskin/style-overrides-by-page.scss";
import "./style/motion/motion.scss";

function App() {
  return (
    <Routes>
      <Route path={process.env.PUBLIC_URL + '/'} element={<Start />} />

      <Route path={process.env.PUBLIC_URL + '/home'} element={<Home />} />

      <Route path={process.env.PUBLIC_URL + '/buttons'} element={<Buttons />} />
      <Route path={process.env.PUBLIC_URL + '/charts'} element={<Charts />} />

      <Route path={process.env.PUBLIC_URL + '/higher-education'} element={<College />} />
      <Route path={process.env.PUBLIC_URL + '/higher-education/students'} element={<Students />} />
      <Route path={process.env.PUBLIC_URL + '/higher-education/educators'} element={<Educators />} />
      <Route path={process.env.PUBLIC_URL + '/isbn-search'} element={<ISBNSearch />} />
      <Route path={process.env.PUBLIC_URL + '/:subject'} element={<Pdp />} />
      <Route path={process.env.PUBLIC_URL + '/:subject/cart'} element={<Cart />} />
      <Route path={process.env.PUBLIC_URL + '/:subject/pcom-signin'} element={<PcomSignin />} />
      <Route path={process.env.PUBLIC_URL + '/:subject/checkout'} element={<Checkout />} />
      <Route path={process.env.PUBLIC_URL + '/:subject/order-confirmation'} element={<OrderConfirmation />} />
    </Routes>
  );
}
export default App;
