/** @format */

import React from 'react';
import { useRecoilState } from 'recoil';
import { closeAccordion } from '../../recoil/recoilAtom';
/*icon*/
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
/*css*/
import '../../style/pcom/_cart-bundlePack.scss';
/*images*/
import ChannelsPromoImg from '../../assets/image/channels-promo.png';

export default function BundlePack({ selectedOption, style }) {
	const [isSubscriptionOpen, setIsSubscriptionOpen] =
		useRecoilState(closeAccordion);

	const toggleSubscription = () => {
		setIsSubscriptionOpen(!isSubscriptionOpen);
	};

	return (
		<div className="bundle-pack-wrapper">
			<div className="bundle-pack">
				<div className="bundle-img-text">
					<img
						src={ChannelsPromoImg}
						alt=""
						style={style}
					/>
					<div className="bundle-text">
						<h4>Study & Exam Prep Pack</h4>
						<p>Monthly subscription to Pearson+ study videos and tools.</p>
					</div>
				</div>
				<div className="bundle-price">
					<h4>${selectedOption[1]}/mo</h4>
					<p>Qty 1</p>
				</div>
			</div>

			<div className="subscription">
				<div
					className="header"
					onClick={toggleSubscription}
				>
					<h6>Subscription details</h6>

					{isSubscriptionOpen ? (
						<IoIosArrowDown
							className="arrow"
							size={18}
						/>
					) : (
						<IoIosArrowUp
							className="arrow"
							size={18}
						/>
					)}
				</div>
				{!isSubscriptionOpen && (
					<div className="subscription-content">
						<p>By placing your order for a Study & Exam Prep Pack subscription, you agree to the Pearson+ <span>Subscription agreement </span>and acknowledge our <span>Privacy notice</span>. <b>You authorize automatic monthly payments of ${selectedOption[1]} (plus tax)</b>, and you may cancel this purchase for a full refund within 14 days by contacting <span>Pearson Support</span>. You can disable auto-renewal of the subscription at any time by going to <span>your account</span>.</p>
					</div>
				)}
			</div>
		</div>
	);
}
