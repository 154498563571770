/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import "../../style/pcom/_buttons.scss"; // Extended in see /style/motion

export default function PrimaryButtonMotion({
	title,
	style,
	route,
	data,
	selectedOption,
  className,
  iconArrow
}) {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(route, { state: { data, selectedOption } });
	};
	return (
		<button
			className={`primary-button-container motion ${className}`}
			style={style}
			onClick={handleClick}
		>
			{title}
      {iconArrow == 'true'
        ?
          <svg className="icon-arrow" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true">
            <g fill-rule="evenodd">
              <path className="icon-arrow__line-path" d="M13.13,7H1c-.55,0-1,.45-1,1s.45,1,1,1h12.13c.55,0,1-.45,1-1s-.45-1-1-1Z"/>
              <path className="icon-arrow__tip-path" d="M6.71,7.29L1.71,2.29c-.39-.39-1.02-.39-1.41,0-.39.39-.39,1.02,0,1.41,1.1,1.1,2.2,2.2,3.29,3.29h0s.28.29.28.29c.39.39.39,1.02,0,1.41l-.28.28h0c-1.1,1.11-2.2,2.2-3.29,3.3-.39.39-.39,1.02,0,1.41.39.39,1.02.39,1.41,0l5-5c.39-.39.39-1.02,0-1.41Z"/>
            </g>
          </svg>
        :
        null}
		</button>
	);
}
