/** @format */

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { errorStatus, openAccordion, textInput } from "../../recoil/recoilAtom";
/**css */
import "../../style/pcom/_cart-inputBox.scss";
/*icon*/
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
/*components*/
import ApplyButton from "./ApplyButton";
import infoIcon from "../../assets/image/sms/info.png";
/**data */
import CourseData from '../../assets/data/courses.json';

export default function InputBox() {

	/**find a course */
	const { subject } = useParams();
	const matchedCourse = CourseData.find((course) => course.productSubject === subject);

	const [courseID, setCourseID] = useRecoilState(textInput);
	const [courseDetail, setCourseDetail] = useState(false);
	const [detailOpen, setDetailOpen] = useRecoilState(openAccordion);
	const [error, setError] = useRecoilState(errorStatus);
	const [buttonClicked, setButtonClicked] = useState(false);


  setCourseID(matchedCourse.id);

	const handleCoupon = (e) => {
		setCourseID(e.target.value);
	};
	const toggleDetail = () => {
		setDetailOpen(!detailOpen);
	};

	const handleApply = (e) => {
    e.preventDefault();
		if (courseID === matchedCourse.id ) {
			setCourseDetail(true);
			setError(false);
		} else {
			setCourseDetail(false);
			setError(true);
		}
		setButtonClicked(true);
	};

	console.debug('courseDetail', courseDetail);

	return (
		<>
			{courseDetail && (
				<div className="course-detail-container">
					<div className="header" onClick={toggleDetail}>
						<h6>Course details</h6>
						{detailOpen ? (
							<IoIosArrowUp className="arrow" size={18} />
						) : (
							<IoIosArrowDown className="arrow" size={18} />
						)}
					</div>
					{detailOpen && (
						<div className="course-detail-info">
							<p>Course: {matchedCourse.title}</p>
							<p>Course ID: {matchedCourse.id}</p>
							<p>Instructor: {matchedCourse.instructor}</p>
							<p>End Date: {matchedCourse.endDate}</p>
						</div>
					)}
				</div>
			)}

			{!courseDetail &&(
				<div className="course-input-container">
					<form className="InputContainer" onSubmit={handleApply}>
						<p className="Text">
							Course ID, program ID, or invite link*
							<img
								className="info-icon"
								src={infoIcon}
								alt="info-icon"
								/>
						</p>
						<input
							className="Input"
							type="text"
							placeholder={`Enter '${matchedCourse.id}'`}
							onChange={handleCoupon}
              defaultValue={matchedCourse.id}
						/>

						{buttonClicked && (
							<div className="input-message">
								{error && (
									<p className="ErrorText">
										This ID does not match any registered courses.
									</p>
								)}
								{!error && (
									<p className="SuccessText">
										Your course ID is valid.
									</p>
								)}
							</div>
						)}
					</form>

					<ApplyButton
						type="submit"
						// buttonProps={{ handleApply, disabled: !courseID}}
            buttonProps={{ handleApply}}
						onClick={() => setError(!error)}
					/>
				</div>
			)}

		</>
	);
}
