/** @format */

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/image/pdp/search-white.svg";
import "../../style/pcom/_search.scss";

import coverBiology from "../../assets/image/pdp/biology-small.jpeg";
// import coverSoc from "../../assets/image/pdp/sociology-small.jpg";
import coverMath from "../../assets/image/pdp/math-small.jpg";
import coverMicroBio from "../../assets/image/pdp/microbiology-small.jpeg";
import coverPhysics from "../../assets/image/pdp/physics-small.jpeg";



export default function Search(props) {

  const navigate = useNavigate();

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    navigate(`${process.env.PUBLIC_URL}/isbn-search`);
  }

  function handleNavigate(e) {
    e.currentTarget.blur();
  }

  return (
    <form className="input-container" style={props.style} onSubmit={handleSubmit}>
      <input
        type="search"
        placeholder="What can we help you find?"
        className="search-input"
      />
      <button className="search-icon" type="submit">
        <SearchIcon />
      </button>

      <div className="PCOM-GANKED autocomplete-container" style={{textAlign: 'left'}}>

        <ul class="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front recent-searches" id="algoliaAutosuggest" role="listbox" aria-label="Search results" >
          <li>
            <button class="ui-menu-item__clear-history" tabindex="-1" onClick={stopIt}>Clear history</button>
          </li>
          <li>
            <div class="ui-menu-item__recent-search-text">
              <h6>Recent searches</h6>
            </div>
          </li>
          <li class="ui-menu-item__recent-searches" tabindex="-1" role="option">
            <a href="https://www.pearson.com/en-us/search.html?aq=science" class="ui-menu-item__recent-searches" tabindex="-1" onClick={stopIt}>
              <p class="recent-search__title">science</p>
            </a>
          </li>
          <li class="ui-menu-item__recent-searches" tabindex="-1" role="option">
            <a href="https://www.pearson.com/en-us/search.html?aq=biology" class="ui-menu-item__recent-searches" tabindex="-1" onClick={stopIt}>
              <p class="recent-search__title">biology</p>
            </a>
          </li>
          <li>
            <div class="ui-menu-item__recent-search-text">
              <h6>Recently viewed</h6>
            </div>
          </li>


          <li class="ui-menu-item__recently-viewed" tabindex="-1" role="option">
            <Link to={`${process.env.PUBLIC_URL}/biology`} tabindex="-1" class="ui-menu-item" onClick={handleNavigate}>
              <div class="search-product__image">
                <img src={coverBiology} alt="" />
              </div>
              <div class="search-product__details">
                <p class="search-product__title">Campbell Biology</p>
                <p class="search-product__author">Lisa A. Urry, Michael L. Cain, Steven A. Wasserman, Peter V. Minorsky, Rebecca B. Orr</p>
              </div>
            </Link>
          </li>
          <li class="ui-menu-item__recently-viewed" tabindex="-1" role="option">
            <Link to={`${process.env.PUBLIC_URL}/math`} tabindex="-1" class="ui-menu-item" onClick={handleNavigate}>
              <div class="search-product__image">
                <img src={coverMath} alt="" />
              </div>
              <div class="search-product__details">
                <p class="search-product__title">Math Lit: A Pathway to College Mathematics</p>
                <p class="search-product__author">Kathleen Almy Rock Valley College| Heather Foes</p>
              </div>
            </Link>
          </li>
          <li class="ui-menu-item__recently-viewed" tabindex="-1" role="option">
            <Link to={`${process.env.PUBLIC_URL}/microbiology`} tabindex="-1" class="ui-menu-item" onClick={handleNavigate}>
              <div class="search-product__image">
                <img src={coverMicroBio} alt="" />
              </div>
              <div class="search-product__details">
                <p class="search-product__title">Microbiology: An Introduction</p>
                <p class="search-product__author">Gerard J. Tortora Bergen Community College| Berdell R. Funke North Dakota State University| Christine L. Case Skyline College</p>
              </div>
            </Link>
          </li>
          <li class="ui-menu-item__recently-viewed" tabindex="-1" role="option">
            <Link to={`${process.env.PUBLIC_URL}/physics`} tabindex="-1" class="ui-menu-item" onClick={handleNavigate}>
              <div class="search-product__image">
                <img src={coverPhysics} alt="" />
              </div>
              <div class="search-product__details">
                <p class="search-product__title">Physics: Principles and Applications</p>
                <p class="search-product__author">Douglas C. Giancoli</p>
              </div>
            </Link>
          </li>


        </ul>


      </div>
    </form>
  );
}
