/** @format */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { errorStatus } from "../../recoil/recoilAtom";
import "../../style/pcom/_cart-summary.scss";
/*icon*/
import { IoIosArrowDown } from "react-icons/io";

export default function Summary({ selectedOption }) {
	/**price */
	const subtotal = parseFloat(selectedOption[selectedOption.length - 1]);
	const estimatedTax = (subtotal * 0.1).toFixed(2);
	const total = subtotal + parseFloat(estimatedTax);

	/**checkout button */
	const { subject } = useParams();
	const [hasError, setHasError] = useRecoilState(errorStatus);
	const navigate = useNavigate();
	const handleClick = () => {
		setHasError(!hasError);
		navigate(`${process.env.PUBLIC_URL}/${subject}/pcom-signin`);
	};
	useEffect(() => {
		setHasError(true);
	}, []);

	return (
		<div className="summary-container">
			<div className="row">
				<p>Subtotal (2 items)</p>
				<p>${subtotal}</p>
			</div>

			<div className="row">
				<p>Estimated Tax
					<span>
						Edit<IoIosArrowDown className="edit-arrow" size={16} />
					</span>
				</p>
				<p>${estimatedTax}</p>
			</div>

			<div className="add-promo">
				<p>Add promo code</p>
				<IoIosArrowDown className="arrow" size={18} />
			</div>

			<div className="row total">
				<h4>Estimated total </h4>
				<h3>${total.toFixed(2)}</h3>
			</div>

			<button
				className="action-button-container"
				disabled={hasError}
				onClick={handleClick}
			>Continue to checkout</button>
		</div>
	);
}
