/** @format */

import React from 'react';
import { IoMdCheckbox, IoMdSquareOutline } from 'react-icons/io';
import styled from 'styled-components';

export default function CheckButton({
	id,
	checked,
	label,
	name,
	value,
	handleCheckChange,
}) {
	return (
		<InputLabel>
			<input
				id={id}
				type="check"
				value={value}
				name={name}
				checked={checked}
				style={{ display: 'none' }}
			/>
			{checked ? (
				<IoMdCheckbox className="radio-icon-checked" />
			) : (
				<IoMdSquareOutline className="radio-icon-unchecked" />
			)}

			<Text onClick={handleCheckChange}>{label}</Text>
		</InputLabel>
	);
}

const InputLabel = styled.div`
	display: flex;
	flex-direction: row;
`;

const Text = styled.label`
	font-family: var(--typefamily--primary-stack);
	color: #333;
	font-size: 0.875rem;
	font-weight: 400;
	text-align: left;
	width: 100%;
	cursor: pointer;
`;
