/** @format */

import React from "react";
import { useParams } from "react-router-dom";
import {
	MdOutlineRadioButtonChecked,
	MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import "../../style/pcom/_radioInput.scss";

export default function RadioInput({id, name, value, checked, onChange, label, disabled}) {
	const { subject } = useParams();
	const radioSelect = () => {
		onChange(value);
	};

  var isDisabled = disabled ? true : false;
  console.debug(name, disabled, isDisabled);

	return (
		<div className={`radio-container ${checked ? "input-checked" : ""}`}>
			<label htmlFor={id}>
				<div className="label-title">
					<div>
						<input
							type="radio"
							id={id}
							name={name}
							value={value}
							checked={checked}
							onChange={radioSelect}
							style={{ display: "none" }}
              disabled={isDisabled}
						/>
						{checked ? (
							<MdOutlineRadioButtonChecked className="radio-icon-checked" />
						) : (
							<MdOutlineRadioButtonUnchecked className="radio-icon-unchecked" />
						)}
						{label}
					</div>
					<div className="price">

						{subject === "chemistry" ? (
						<p>${value[0]}<span>/mo</span></p>
						) : (<p>${value[0]}&nbsp;<span> once</span></p>)}
					
						{name === "bundleOption" &&
							(subject === "chemistry" ? null : (
								<p><span>&nbsp;+&nbsp;</span> ${value[1]}<span>/mo</span></p>
							))}
					</div>
				</div>

				<div className="isbn">
					<span>ISBN-13: {value[2]}</span>
				</div>
			</label>
		</div>
	);
}
