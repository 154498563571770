/** @format */

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as DesktopLogo } from "../../assets/image/pcom-reskin/logo-on-dark.svg";

export default function PsoLogo() {
	return (
		<Logo>
			<Link to={process.env.PUBLIC_URL + "/"}>
				<DesktopLogo />
			</Link>
		</Logo>
	);
}

const Logo = styled.div`
	margin-right: 3rem;
	padding: 0;
  min-height: 67px;
  display: flex;
  align-items: center;
`;
