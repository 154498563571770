/** @format */
import React from "react";
import { useRecoilState } from "recoil";
import { radioSelect } from "../../recoil/recoilAtom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../../style/pcom/_pdp-priceTab.scss";

/**icon */
import { FaCheck } from "react-icons/fa6";
/**component */
import BundleOption from "./BundleOption";
import ActionButton from "../global/ActionButton";
import NonBundleButton from '../global/NonBundleButton';
import RegularOption from "./RegularOption";

export default function PriceTab({ tabInfo, defaultTab, title, subject}) {
	const [selectedOption, setSelectedOption] = useRecoilState(radioSelect);
	const handleRadioSelect = (option) => {
		setSelectedOption(option);
	};

	return (
		<Tabs className="tab-container" defaultIndex={defaultTab}>
			<TabList className="tab-list">
				{Object.entries(tabInfo).map(([item, tabData]) => (
					<Tab key={item} className="tab">
						<h1>{item}</h1>
						<p>{tabData.price}</p>
					</Tab>
				))}
			</TabList>

			{Object.entries(tabInfo).map(([item, tabData]) => (
				<TabPanel key={item} className="tab-content">
					<div className="price-container">
						{tabData.option && (
							<>
								<div className="bundle-container">
									{tabData.option.bundle.supress != true && (
										<BundleOption
											title={title}
											platform={item}
											bundleItem={tabData}
											onOptionChange={handleRadioSelect}
										/>
									)}

									<RegularOption
										title={title}
										platform={item}
										regularItem={tabData}
										onOptionChange={handleRadioSelect}
									/>
								</div>

								{/**button +price on the right side */}
								<div className="button-container">
									{defaultTab === 0 &&
									selectedOption !== null ? (
										/**chemistry - buy now button*/
										<div className="selected-price OVERRIDE-PDP">
											<h1>${selectedOption[0]} /mo</h1>
											<p>
												4-month term, pay monthly or pay
												$63.96 upfront
											</p>
											{selectedOption[1] === "" ? (
												<NonBundleButton/>
											):(
												<ActionButton
													title="Buy Now"
													route="cart"
													style={{marginTop: "15px",minWidth: "167px"}}
												/>
											)}
										</div>
									) : selectedOption == null ? (
										/** default */
										<div className="selected-price OVERRIDE-PDP">
											{subject == 'sociology' ? (
												<>
													<h1>${tabData.option.regular.radioLabel.single[4]}</h1>
													<p>due today</p>
												</>
											) : (
												<>
													<h1>${tabData.option.bundle.radioLabel.single[5]}</h1>
													<p>due today, then ${tabData.option.bundle.radioLabel.single[0]}/mo</p>
												</>
											)}

										</div>
									) : (
										/**when actually selected */
										<div className="selected-price OVERRIDE-PDP">
											{selectedOption[1] === "" ? (
												<>
													<h1>${selectedOption[0]}</h1>
													<p>due today</p>
													<NonBundleButton/>
												</>
											) : (
												<>
													<h1>${selectedOption[5]}</h1>
													<p>due today, then ${selectedOption[1]} /mo</p>
													<ActionButton
														title="Buy Now"
														route="cart"
														style={{marginTop: "15px",minWidth: "167px"}}
													/>
												</>
											)}
										</div>
									)}

				
								</div>
							</>
						)}
					</div>

					{/**Detail features at the bottom of the tab content */}
					{tabData.detail && (
						<>
							<div className="detail-feature">
								<ul>
									<h3>{tabData.detail.title[0]}</h3>
									{tabData.detail.include.map(
										(includeItem, i) => (
											<li key={i}>
												<FaCheck className="check-icon" />
												{includeItem}
											</li>
										)
									)}
								</ul>

								{(tabData.option.bundle.radioLabel.multi ===
									selectedOption ||
									tabData.option.bundle.radioLabel.single ===
										selectedOption) && (
									<ul className="study-pack">
										<h3>{tabData.detail.title[1]}</h3>
										{tabData.detail.features.map(
											(featureItem, i) => (
												<li key={i}>
													<FaCheck className="check-icon" />
													{featureItem}
												</li>
											)
										)}
									</ul>
								)}
							</div>
						</>
					)}

					{tabData.detail && (
						<ul className="policy">
							{tabData.detail.policy.map((policyItem, i) => (
								<li key={i}>
									<i>{policyItem}</i>
								</li>
							))}
						</ul>
					)}
				</TabPanel>
			))}
		</Tabs>
	);
}
