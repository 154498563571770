/** @format */

import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

/**styles */
import '../../style/pcom-reskin/_ganked.scss';

/** images **/
import imgPromoA from "../../assets/image/pcom-reskin/ganked/students/promo-a.jpg";
import imgPromoB from "../../assets/image/pcom-reskin/ganked/students/promo-b.jpg";
import imgPromoC from "../../assets/image/pcom-reskin/ganked/students/promo-c.jpg";
import imgPromoD from "../../assets/image/pcom-reskin/ganked/students/promo-d.jpg";
import imgPromoE from "../../assets/image/pcom-reskin/ganked/students/promo-e.jpg";

import imgPromoF from "../../assets/image/pcom-reskin/ganked/students/promo-f.jpg";
import imgPromoG from "../../assets/image/pcom-reskin/ganked/students/promo-g.jpg";
import imgPromoH from "../../assets/image/pcom-reskin/ganked/students/promo-h.jpg";
import imgPromoI from "../../assets/image/pcom-reskin/ganked/students/promo-i.jpg";

import imgPromoJ from "../../assets/image/pcom-reskin/ganked/students/promo-j.jpg";

/**components */
import TopStrapNav from '../../components/pcom-reskin/TopStrapNav';
import Nav from '../../components/pcom-reskin/Nav';
import FooterCollege from '../../components/pcom-reskin/FooterCollege';
import Breadcrumb from "../../components/global/Breadcrumb";


export default function Students() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  const steps = [
    { label: "Home Page", url: "/#" },
    { label: "Higher education", url: "/#" },
    { label: "Students", url: "/#" }
  ];

	return (
    <>
      <TopStrapNav collegeActive='true' />
      <Nav isSticky='true' />

      <div className="PCOM-GANKED students">
        <Breadcrumb steps={steps} />

		    <div className="page basicpage dots-lrDown-textureLight">
          <main role="main" id="main-content-starts">
            <div>
              <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                <section class="column-control flex-layout--vertically-centered bgcolor--default curve-bottom-right clip-target-bg-media default-texture default-animation default-gutters has-padding-top--90 has-padding-bottom--90 default-padding-horizontal min-height--600 default-rule default-corner-radius default-shadow aem-GridColumn c-gradient-overlay aem-GridColumn--default--12">
                  <div class="OVERRIDE-STUDENTS column-control--bg bg1396052241 column-control--mask-dark-solid"></div>
                  <div class="container">
                    <div class="row">
                      <div class="col-md-8 col-12">
                        <div class="text screenreader section">
                          <h1>Learner collection</h1>
                        </div>
                        <div class="target parbase section">
                          <div id="_content_global-store_sites_en-us_higher-education_students_jcr_content_root_par-main_column_control_109f__938341409_par-col-1_embed_copy" class="campaign " style={{'visibility': 'visible'}}>
                            <div class="default embed section">
                              <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content-tile default-design-template behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default default-links default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption use-margin-top-none color--text-primary-inverse title-typesize--h1 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <div class="content-tile-text rte-container">
                                  <h1>Pearson+<br />
                                    eTextbooks for $10.99/mo
                                  </h1>
                                  <h4>Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more.</h4>
                                  <p><b><i>eTextbook minimum term of 4 months for $43.96</i></b></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content-tile default-design-template behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default default-links use-button-primary default-btn-size default-btn-width pad--default__figure pad-bottom--0__pad-top--30__caption pad-eq-horizontal--default__caption use-margin-top-none color--text-primary-inverse title-typesize--h1 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <div class="content-tile-text rte-container">
                                  <p><a href="https://www.pearson.com/en-us/pearsonplus.html" onClick={stopIt} className="stopIt OVERRIDE-STUDENTS primarybtn">Check it out</a><br /></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12"></div>
                    </div>
                  </div>
                </section>
                <section class="column-control layout-default clipping-default clip-target-default default-texture default-animation default-gutters default-padding-top has-padding-bottom--45 default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-2 col-12"></div>
                      <div class="col-md-8 col-12">
                        <div class="content-tile default-design-template behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--default default-links default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-title-typesize default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <div class="content-tile__figcaption">
                                <div class="content-tile-text rte-container">
                                  <h2 style={{'textAlign': 'center'}}>The tools you need for the grade you want</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 col-12"></div>
                    </div>
                  </div>
                </section>
                <section class="column-control flex-layout clipping-default clip-target-default default-texture default-animation default-gutters has-padding-top--none default-padding-bottom default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-xl-3 col-md-6 col-12">
                        <div class="OVERRIDE-STUDENTS content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--background-light text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{'--original-image-ratio': '2'}}>
                                <img loading="lazy" src={imgPromoA} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">MyLab</h3>
                                <div class="content-tile-text rte-container">
                                  <p><a adhocenable="false" href="https://portal.mypearson.com/portal" aria-label="Sign in to MyLab. Opens new tab." target="_blank" class="new-window stopIt" rel="noreferrer" onClick={stopIt}>Sign in</a><br />
                                    <a adhocenable="false" href="https://www.pearsonmylabandmastering.com/northamerica/students/get-registered/index.html" style={{'fontSize': '12px'}} aria-label="Register for access to MyLab. Opens new tab." target="_blank" class="new-window stopIt" rel="noreferrer" onClick={stopIt}>Register for access</a><br />
                                    <a href="https://www.pearson.com/en-us/higher-education/products-services/mylab.html" className="stopIt" onClick={stopIt}>Discover MyLab<sup>®</sup></a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12">
                        <div class="OVERRIDE-STUDENTS content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--background-light text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{'--original-image-ratio': '2'}}>
                                <img loading="lazy" src={imgPromoB} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">Mastering</h3>
                                <div class="content-tile-text rte-container">
                                  <p><a adhocenable="false" href="https://portal.mypearson.com/portal" aria-label="Sign in to Mastering. Opens new tab." target="_blank" class="new-window stopIt" rel="noreferrer" onClick={stopIt}>Sign in</a><br />
                                    <a adhocenable="false" href="https://www.pearsonmylabandmastering.com/northamerica/students/get-registered/index.html" style={{'fontSize': '12px'}} aria-label="Register for access to Mastering. Opens new tab." target="_blank" class="new-window stopIt" rel="noreferrer" onClick={stopIt}>Register for access</a><br />
                                    <a href="https://www.pearson.com/en-us/higher-education/products-services/mastering.html" className="stopIt" onClick={stopIt}>Discover Mastering<sup>®</sup></a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12">
                        <div class="OVERRIDE-STUDENTS content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--background-light text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{'--original-image-ratio': '2'}}>
                                <img loading="lazy" src={imgPromoC} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">Revel</h3>
                                <div class="content-tile-text rte-container">
                                  <p><a adhocenable="false" href="https://console.pearson.com/signin" aria-label="Sign in to Revel. Opens new tab." target="_blank" class="new-window stopIt" rel="noreferrer" onClick={stopIt}>Sign in</a><br />
                                    <a adhocenable="false" href="https://www.pearsonhighered.com/revel/students/registration/" style={{'fontSize': '12px'}} aria-label="Register for access to Revel. Opens new tab." target="_blank" class="new-window stopIt" rel="noreferrer" onClick={stopIt}>Register for access</a><br />
                                    <a href="https://www.pearson.com/en-us/higher-education/products-services/revel.html" className="stopIt" onClick={stopIt}>Discover Revel<sup>®</sup></a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12">
                        <div class="OVERRIDE-STUDENTS content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--background-light text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{'--original-image-ratio': '2'}}>
                                <img loading="lazy" src={imgPromoD} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h3 class="content-tile__title">Pearson+</h3>
                                <div class="content-tile-text rte-container">
                                  <p><a adhocenable="false" href="https://etext-ise.pearson.com/bookshelf" aria-label="Sign in to Pearson+. Opens new tab." target="_blank" class="new-window stopIt" rel="noreferrer" onClick={stopIt}>Sign in</a><br />
                                    <a href="https://exchange.pearson.com/redeemcode" aria-label="Register for access to Pearson+. Opens new tab." target="_blank" class="new-window stopIt" rel="noreferrer" onClick={stopIt}>Register for access</a><br />
                                    <a href="https://www.pearson.com/en-us/higher-education/products-services/pearsonplus.html" className="stopIt" onClick={stopIt}>Discover Pearson eTextbook</a><br />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="column-control layout-default bgcolor--default clipping-default clip-target-default default-texture default-animation default-gutters has-padding-top--none default-padding-bottom default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <div class="content-tile content-tile-color-block--full-img--text-right behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--ui-04 default-links use-button-tertiary-inverse default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption color--text-primary-inverse default-title-typesize default-title-typeweight title-color--text-primary-inverse default-subtitle-typesize default-subtitle-typeweight subtitle-color--text-primary-inverse default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{'--original-image-ratio': '2.183'}}>
                                <img loading="lazy" src={imgPromoE} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h2 class="content-tile__title">Channels</h2>
                                <div class="content-tile-text rte-container">
                                  <p>Study &amp; exam prep with thousands of video lessons and practice problems.<br /></p>
                                  <p><a class="new-window stopIt" aria-label="Go to Channels. Opens new tab." href="https://www.pearson.com/channels" target="_blank" rel="noreferrer" onClick={stopIt}>Learn more</a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="column-control layout-default bgcolor--background-dark clipping-default clip-target-default default-texture default-animation default-gutters default-padding-top default-padding-bottom default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <div class="static-collection section">
                          <div class="c-collection--dark-bg"></div>
                          <div class="c-collection c-collection--product c-collection--dark c-collection--scroll">
                            <div class="container">
                              <div class="c-collection__header">
                                <h2 class="c-collection__title">Our catalog</h2>
                                <a class="text-primary-link stopIt" href="https://www.pearson.com/en-us/search.html" aria-label="View all titles/collections/options" onClick={stopIt}>
                                View all titles
                                </a>
                              </div>
                              <div data-aria-label="View all titles/collections/options" class="c-collection__row js-c-collection slick-initialized slick-slider" data-slick="{&quot;slidesToShow&quot;: 4, &quot;slidesToScroll&quot;: 4, &quot;infinite&quot;: true}" data-next-label-slick="Next" data-previous-label-slick="Previous" role="region">
                                <div class="slick-list draggable">
                                  <div class="slick-track" style={{'opacity': '1'}, {'width': '1472px'}, {'transform': 'translate3d(0px, 0px, 0px)'}}>
                                    <div class="slick-slide slick-current slick-active" data-slick-index="0" role="group" aria-label="Business &amp; Economics" style={{'width': '368px'}}>
                                      <div>
                                        <div class="626469425571797100 static-collection-card c-collection__col-" style={{'width': '100%'}, {'display': 'inline-block'}}>
                                          <a href="/en-us/search.html/Business+&amp;+Economics" class="c-collection__banner stopIt OVERRIDE-STUDENTS" onClick={stopIt}>
                                            <div class="collection__img-wrap">
                                              <picture style={{'--original-image-ratio': '0.8'}}>
                                                <img loading="lazy" src={imgPromoF} alt="" role="presentation" />
                                              </picture>
                                            </div>
                                            <span class="c-collection__banner-title">Business &amp; Economics</span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="slick-slide slick-active" data-slick-index="1" role="group" aria-label="Computer Science" style={{'width': '368px'}}>
                                      <div>
                                        <div class="889125224970505500 static-collection-card c-collection__col-" style={{'width': '100%'}, {'display': 'inline-block'}}>
                                          <a href="/en-us/search.html/Computer+Science" class="c-collection__banner stopIt OVERRIDE-STUDENTS" onClick={stopIt}>
                                            <div class="collection__img-wrap">
                                              <picture style={{'--original-image-ratio': '0.781'}}>
                                                <img loading="lazy" src={imgPromoG} alt="" role="presentation" />
                                              </picture>
                                            </div>
                                            <span class="c-collection__banner-title">Computer Science</span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="slick-slide slick-active" data-slick-index="2" role="group" aria-label="Mathematics" style={{'width': '368px'}}>
                                      <div>
                                        <div class="517162177525089660 static-collection-card c-collection__col-" style={{'width': '100%'}, {'display': 'inline-block'}}>
                                          <a href="/en-us/search.html/Mathematics" class="c-collection__banner stopIt OVERRIDE-STUDENTS" onClick={stopIt}>
                                            <div class="collection__img-wrap">
                                              <picture style={{'--original-image-ratio': '0.781'}}>
                                                <img loading="lazy" src={imgPromoH} alt="" role="presentation" />
                                              </picture>
                                            </div>
                                            <span class="c-collection__banner-title">Mathematics</span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="slick-slide slick-active" data-slick-index="3" role="group" aria-label="Science" style={{'width': '368px'}}>
                                      <div>
                                        <div class="587335818277269400 static-collection-card c-collection__col-" style={{'width': '100%'}, {'display': 'inline-block'}}>
                                          <a href="/en-us/search.html/Science" class="c-collection__banner stopIt OVERRIDE-STUDENTS" onClick={stopIt}>
                                            <div class="collection__img-wrap">
                                              <picture style={{'--original-image-ratio': '0.781'}}>
                                                <img loading="lazy" src={imgPromoI} alt="" role="presentation" />
                                              </picture>
                                            </div>
                                            <span class="c-collection__banner-title">Science</span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="column-control layout-default bgcolor--default clipping-default clip-target-default default-texture default-animation default-gutters default-padding-top default-padding-bottom default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow aem-GridColumn aem-GridColumn--default--12">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <div class="OVERRIDE-STUDENTS add24borders content-tile content-tile-color-block--full-img behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--ui-04 default-links use-button-tertiary-inverse default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption color--text-primary-inverse default-title-typesize default-title-typeweight title-color--text-primary-inverse default-subtitle-typesize default-subtitle-typeweight subtitle-color--text-primary-inverse default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                          <div class="content-tile-container">
                            <div class="content-tile__figure">
                              <picture style={{'--original-image-ratio': '3'}}>
                                <img loading="lazy" src={imgPromoJ} alt="" role="presentation" />
                              </picture>
                              <div class="content-tile__figcaption">
                                <h2 class="content-tile__title">Get more out of college</h2>
                                <div class="content-tile-text rte-container">
                                  <p>Grow your professional network by getting work experience and improving your resume<br /></p>
                                  <p><a href="https://www.pearson.com/en-us/highered-students/student-programs.html" className="stopIt" onClick={stopIt}>Explore student programs</a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div aria-live="assertive" style={{'position': 'absolute'}, {'color': 'transparent'}, {'bottom': '26px'}} id="accessibility__announcement"></div>
            <a class="to-top-button" href="#main-content-starts">
            <span class="screenreader">Back to</span>
            <span class="to-top-button__title">top</span>
            </a>
          </main>
        </div>

        <FooterCollege />

  		</div>
    </>
	);
}
