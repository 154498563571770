/** @format */

import React, { useState } from "react";
import "../../style/pcom/_checkout-payment.scss";

/*icon*/
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked,} from "react-icons/md";

/**components */
import CardInput from "./CardInput";
import CheckButton from "../global/CheckButton";
import ActionButton from "../global/ActionButton";

export default function CreditCard({ subject, selectedCourse, selectedPayment, handleRadioChange, disabled}) {

	/**check button */
	const [saveCard, setSaveCard] = useState(false);
	const handleSaveCard = () => {
		setSaveCard(!saveCard);
	};
	const [useShippingAddress, setUseShippingAddress] = useState(true);
	const handleShippingAddress = () => {
		setUseShippingAddress(!useShippingAddress);
	};

	console.debug("CreditCard: Selected Course", selectedCourse);
  console.debug('disabled?', disabled);

	return (
		<div className={`payment-container ${disabled == 'true'? 'disabled' : ''}`}>
			<div className="radio-container">
				<input
					type="radio"
					id="credit-card"
					name="payment"
					value="credit-card"
					checked={selectedPayment === 'credit-card'}
					onChange={handleRadioChange}
          disabled={disabled == 'true' ? 'true' : 'fasle'}
				/>

				<label for="credit-card">
					{selectedPayment === 'credit-card' ? (
						<MdOutlineRadioButtonChecked className="radio-icon-checked" />
					) : (
						<MdOutlineRadioButtonUnchecked className="radio-icon-unchecked" />
					)}

					<p>Add new credit card</p>
					<img src={require(`../../assets/image/pdp/credit-cards.png`)} alt="credit-card"/>
				</label>
			</div>

			<div className="description">
				<div className="credit-card-inputs">
					<CardInput type="text" label="Card Number" value="1001 1234 4567 6789"/>
					<div className="row">
						<CardInput
							type="text"
							label="Expiration Month"
							placeholder="MM"
							value="10"
						/>
						<CardInput
							type="text"
							label="Expiration Year"
							placeholder="YY"
							value="26"
						/>
						<CardInput
							type="text"
							label="Security code"
							value="012"
						/>
					</div>
					<CheckButton
						id="save-card"
						label="Save card"
						checked={saveCard}
						handleCheckChange={handleSaveCard}
					/>
				</div>

				<div className="billing-inputs">
					<h2>Billing Address</h2>
					<CheckButton
						id="address"
						label="Use as shipping address"
						checked={useShippingAddress}
						handleCheckChange={handleShippingAddress}
					/>
					<CardInput
						disabled="true"
						type="text"
						label="Country"
						placeholder="United States"
					/>
					<CardInput type="text" label="First Name" value="Charlie" />
					<CardInput type="text" label="Last Name" value="Frost" />
					<CardInput
						type="text"
						label="Street Address"
						value="683 Jefferson Street"
					/>
					<CardInput
						type="text"
						label="Apartment / Suite / Building (optional)"
					/>
					<CardInput type="text" label="City" value="Tiburon" />
					<CardInput type="text" label="State/Province" value="CA" />
					<CardInput
						type="text"
						label="ZIP/Postal Code"
						value="94123"
					/>
					<CardInput
						type="text"
						label="Phone number"
						placeholder="( _ _ _ ) _ _ _ - _ _ _ _"
						value="(123) 456-7890"
					/>

					<p className="legal-policy">
						By clicking “Place order,” you agree to the Pearson+{" "}
						<span>Subscription agreement</span> and acknowledge our{" "}
						<span>Privacy notice</span>. You will have two charges
						today: a one-time charge for your courseware access, and
						the first of a recurring $6.99 (plus tax) monthly charge
						for the Study & Exam Prep Pack subscription. You can
						cancel this purchase for a full refund within 14 days by
						contacting <span>Pearson Support</span>, and you can
						disable auto-renew on the subscription by going to{" "}
						<span>your account</span>.
					</p>
					<ActionButton
						title="Place order"
						style={{
							width: "360px",
							margin: "0 auto",
							height: "48px",
							display: "inline-flex",
							justifyContent: "center",
						}}
						route={`${process.env.PUBLIC_URL}/${subject}/order-confirmation`}
						selectedCourse={selectedCourse}
					/>
				</div>
			</div>
		</div>
	);
}
