/** @format */

import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

/**styles */
import "../../style/pcom-reskin/_ganked.scss";

/**components */
import TopStrapNav from '../../components/pcom-reskin/TopStrapNav';
import Nav from '../../components/pcom-reskin/Nav';
import FooterCollege from '../../components/pcom-reskin/FooterCollege';

/** images **/
import imgPromoA from "../../assets/image/pcom-reskin/ganked/college/promo-a.jpg";
import imgPromoB from "../../assets/image/pcom-reskin/ganked/college/promo-b.jpg";
import imgPromoC from "../../assets/image/pcom-reskin/ganked/college/promo-c.png";

export default function HigherEdCollege() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

	return (
    <>
      <TopStrapNav collegeActive='true' />
      <Nav isTransparent='true' isSticky='true' />

      <div className="PCOM-GANKED college">
        <div className="page basicpage dots-lrDown-textureLight">
          <main role="main" id="main-content-starts">
            <div>
              <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                <section className="column-control flex-layout--vertically-centered clipping-default min-height--ideal-hero aem-GridColumn aem-GridColumn--default--12">
                  <div className="column-control--bg bg937267452 column-control--mask-dark-gradient"></div>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <section className="column-control section">
                          <div className="row">
                            <div className="col-md-8 col-12">
                              <div className="target parbase section">
                                <div id="_content_global-store_sites_en-us_higher-education_jcr_content_root_par-main_column_control_copy__par-col-1_column_control_par-col-1_embed_copy" className="campaign " style={{'visibility': 'visible'}}>
                                  <div className="default embed section">
                                    <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="content-tile pad-left--0__pad-right--60__caption color--text-primary-inverse section">
                                <div className="content-tile-container">
                                  <div className="content-tile__figure">
                                    <div className="content-tile__figcaption">
                                      <div className="content-tile-text rte-container">
                                        <h1>The tools you need for the results you want</h1>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <section className="column-control flex-layout--pin-cta bgcolor--default default-padding-horizontal section">
                                <div className="row">
                                  <div className="col-md-6 col-12">
                                    <div className="OVERRIDE-COLLEGE content-tile content-tile-panel-item-main behavior--none content-tile-portrait bgcolor--default default-links use-button-primary default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h4 title-typeweight--semibold title-color--text-primary default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-corner-radius section">
                                      <div className="content-tile-container">
                                        <div className="content-tile__figure">
                                          <div className="content-tile__figcaption">
                                            <div className="content-tile-text rte-container">
                                              <h3><b><span className="minor">COLLEGE STUDENTS</span><br/>
                                                </b>Pearson+<br/>
                                                eTextbooks for $10.99/mo.<br/>
                                              </h3>
                                              <p>Choose from over 2,000 eTextbooks with audio, flashcards, notes and more.</p>
                                              <p><i>eTextbook minimum term of 4-months for $43.96.</i></p>
                                              <p><a href="https://www.pearson.com/en-us/pearsonplus.html" onClick={stopIt} className="stopIt OVERRIDE-COLLEGE primarybtn">Check it out</a></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12">
                                    <div className="OVERRIDE-COLLEGE content-tile content-tile-panel-item-main behavior--none content-tile-portrait bgcolor--ui-01 text-primary-link use-button-primary default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h4 title-typeweight--semibold title-color--text-primary default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count corners-semiround-md section">
                                      <div className="content-tile-container">
                                        <div className="content-tile__figure">
                                          <div className="content-tile__figcaption">
                                            <div className="content-tile-text rte-container">
                                              <h3><b><span className="minor">COLLEGE EDUCATORS</span><br/>
                                                </b>Digital learning platforms for every classroom
                                              </h3>
                                              <p>Discover how MyLab, Mastering, Revel and Pearson+ can help drive academic success.</p>
                                              <p><a href="https://www.pearson.com/en-us/higher-education/educators.html" onClick={stopIt} className="stopIt OVERRIDE-COLLEGE primarybtn">Explore now</a></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div className="col-md-4 col-12"></div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="column-control bgcolor--default has-padding-top--none has-padding-bottom--30 pull-up-5-percent aem-GridColumn aem-GridColumn--default--12">
                  <div className="container">
                    <div className="row">
                      <div className="col-10pct col-12"></div>
                      <div className=" col-80pct col-12">
                        <div className="content-tile bgcolor--background-light pad--30__figure corners-semiround-md section">
                          <div className="content-tile-container">
                            <div className="content-tile__figure">
                              <div className="content-tile__figcaption">
                                <div className="content-tile-text rte-container">
                                  <h2 style={{'textAlign': 'center'}}>Resources for students and educators</h2>
                                  <p style={{'textAlign': 'center'}}>Find tools that drive effective learning in the classroom&nbsp;</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" col-10pct col-12"></div>
                    </div>
                  </div>
                </section>
                <section className="column-control has-padding-top--none default-padding-bottom aem-GridColumn aem-GridColumn--default--12">
                  <div className="container">
                    <div className="row">
                      <div className="col-10pct col-12"></div>
                      <div className=" col-80pct col-12">
                        <section className="column-control flex-layout--pin-cta has-padding-top--none has-padding-bottom--none section">
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--background-light text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                                <div className="content-tile-container">
                                  <div className="content-tile__figure">
                                    <picture style={{'--original-image-ratio': '2'}}>
                                      <img loading="lazy" src={imgPromoA} alt="" role="presentation" />
                                    </picture>
                                    <div className="content-tile__figcaption">
                                      <h3 className="content-tile__title">Shop for your courses</h3>
                                      <div className="content-tile-text rte-container">
                                        <p>Find your eTextbook, download our study tools, log into your learning platform and more</p>
                                        <p><a href="https://www.pearson.com/en-us/search.html" onClick={stopIt} className="stopIt">Shop now</a></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--background-light text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                                <div className="content-tile-container">
                                  <div className="content-tile__figure">
                                    <picture style={{'--original-image-ratio': '2'}}>
                                      <img loading="lazy" src={imgPromoB} alt="" role="presentation" />
                                    </picture>
                                    <div className="content-tile__figcaption">
                                      <h3 className="content-tile__title">Discover resources for your discipline</h3>
                                      <div className="content-tile-text rte-container">
                                        <p>Browse titles and teaching resources by discipline, explore tech for personalized learning and more</p>
                                        <p><a href="https://www.pearson.com/en-us/higher-education/resources-by-discipline.html" onClick={stopIt} className="stopIt">Explore</a></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className=" col-10pct col-12"></div>
                    </div>
                  </div>
                </section>
                <section className="column-control bgcolor--default has-padding-top--30 default-padding-bottom aem-GridColumn aem-GridColumn--default--12">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="embed section">
                          <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                            <section className="column-control has-padding-top--none has-padding-bottom--30 use-margin-top-none aem-GridColumn aem-GridColumn--default--12">
                              <div className="container">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="content-tile content-tile-color-block--half-img content-tile-landscape--50-50 bgcolor--background-dark section">
                                      <div className="content-tile-container">
                                        <div className="content-tile__figure">
                                          <picture style={{'--original-image-ratio': '1'}}>
                                            <img loading="lazy" src={imgPromoC} alt="" role="presentation" />
                                          </picture>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="OVERRIDE-COLLEGE add24borders content-tile default-design-template content-tile-landscape--50-50-reverse vertically-centered bgcolor--background-dark use-button-primary pad--30__figure use-margin-top-none color--text-primary-inverse section">
                                      <div className="content-tile-container">
                                        <div className="content-tile__figure">
                                          <picture style={{'--original-image-ratio': '1'}}>
                                            <img loading="lazy" src={imgPromoC} alt="" role="presentation" />
                                          </picture>
                                          <div className="content-tile__figcaption">
                                            <div className="content-tile-text rte-container">
                                              <h1><b>Channels</b></h1>
                                              <h3><b>Study &amp; exam prep, available in Pearson+</b></h3>
                                              <h4><b>Watch videos from experts<br/>
                                                </b>Quickly understand complex concepts.
                                              </h4>
                                              <h4><b>Test your knowledge<br/>
                                                </b>Study for exams with practice questions.
                                              </h4>
                                              <h4><b>Ask a question, get an answer<br/>
                                                </b>Get help from experienced tutors.<br/>
                                                <br/>
                                              </h4>
                                              <p><a href="https://www.pearson.com/channels?CEP=pearsonHP" onClick={stopIt} className="OVERRIDE-COLLEGE primarybtn stopIt">Check it out</a></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="column-control bgcolor--ui-01 has-padding-bottom--none aem-GridColumn aem-GridColumn--default--12">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-2 col-12"></div>
                      <div className="col-md-8 col-12">
                        <div className="content-tile section">
                          <div className="content-tile-container">
                            <div className="content-tile__figure">
                              <div className="content-tile__figcaption">
                                <div className="content-tile-text rte-container">
                                  <h2 style={{'textAlign': 'center'}}>Ideas From Our Community</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-12"></div>
                    </div>
                  </div>
                </section>
                <section className="column-control bgcolor--ui-01 has-padding-top--45 default-padding-bottom aem-GridColumn aem-GridColumn--default--12">
                  <div className="container">
                    <div className="row">
                      <div className="col-10pct col-12"></div>
                      <div className=" col-80pct col-12">
                        <section className="column-control flex-layout--pin-cta bgcolor--ui-01 clipping-default clip-target-default default-animation default-gutters has-padding-top--none has-padding-bottom--none default-padding-horizontal default-min-height default-rule default-corner-radius default-shadow section">
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="OVERRIDE-COLLEGE content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--background-light text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                                <div className="content-tile-container">
                                  <div className="content-tile__figure">
                                    <div className="content-tile__figcaption">
                                      <h3 className="content-tile__title">Pearson Students Blog</h3>
                                      <div className="content-tile-text rte-container">
                                        <p>Explore the latest trends, tips, and experiences in college life in this blog written by fellow students.</p>
                                        <p><a href="https://www.pearson.com/en-us/higher-education/insights-and-events/students-blog.html" onClick={stopIt} className="stopIt">Check it out</a></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="OVERRIDE-COLLEGE content-tile content-tile-panel-item-main behavior--none content-tile-portrait default-layout-alignment default-offset bgcolor--background-light text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count default-rule default-corner-radius default-shadow section">
                                <div className="content-tile-container">
                                  <div className="content-tile__figure">
                                    <div className="content-tile__figcaption">
                                      <h3 className="content-tile__title">Teaching &amp; Learning Blog </h3>
                                      <div className="content-tile-text rte-container">
                                        <p>Join the conversation and discover the latest trends, perspectives, and successes in higher education.</p>
                                        <p><a href="https://www.pearson.com/en-us/higher-education/insights-and-events/teaching-and-learning-blog.html" onClick={stopIt} className="OVERRIDE-COLLEGE">Explore insights</a></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className=" col-10pct col-12"></div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div aria-live="assertive" style={{'position': 'absolute'}, {'color': 'transparent'}, {'bottom': '26px'}} id="accessibility__announcement"></div>
            <a className="to-top-button" href="#main-content-starts">
            <span className="screenreader">Back to</span>
            <span className="to-top-button__title">top</span>
            </a>
          </main>
        </div>

        <FooterCollege />
      </div>
    </>


	);
}
