/** @format */

import React from 'react';
import { useRecoilState } from 'recoil';
import { errorStatus } from '../../recoil/recoilAtom';
import styled from 'styled-components';
import { FiAlertCircle } from 'react-icons/fi';

export default function ErrorBanner() {
	const [hasError, setHasError] = useRecoilState(errorStatus);
	return (
		<>
			{hasError && (
				<BannerContainer>
					<FiAlertCircle
						className="icon"
						size={36}
					/>

					<p>
						Your cart includes products that require a valid course ID. This
						should come from your instructor, either as an ID code or an
						invitation link. If you don’t have a course ID, you can save these
						items to purchase later.
					</p>
				</BannerContainer>
			)}
		</>
	);
}

const BannerContainer = styled.div`
	border-radius: 4px;
	border: 1px solid var(--ui-elements-border-01, #dfe1e1);
	background: var(--ui-elements-background-light, #fff);
	max-width: 740px;
	height: fit-content;
	padding: 7px 10px 9px 2px;
	display: flex;
	align-items: flex-start;
	gap: 10px;
	border-left: 3px solid #FC630F;
	margin: 20px 0;
	.icon {
		color: #FC630F;
		align-self: flex-start;
		margin: 0 10px;
	}
	p {
		color: var(--text-text-secondary, #333);
		font-family: var(--typefamily--primary-stack);
		font-size: 0.875rem;
		font-weight: 400;
		text-align: left;
		line-height: 1.5;
	}
`;
