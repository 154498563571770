/** @format */

import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

/**styles */
import "../../style/pcom-reskin/_ganked.scss";


/** images **/
import imgPromoA from "../../assets/image/pcom-reskin/ganked/home/promo-a.jpg";
import imgPromoB from "../../assets/image/pcom-reskin/ganked/home/promo-b.jpg";
import imgPromoC from "../../assets/image/pcom-reskin/ganked/home/promo-c.jpg";
import imgPromoD from "../../assets/image/pcom-reskin/ganked/home/promo-d.jpg";
import imgPromoE from "../../assets/image/pcom-reskin/ganked/home/promo-e.png";
import imgPromoF from "../../assets/image/pcom-reskin/ganked/home/promo-f.jpg";


/**components */
import TopStrapNav from '../../components/pcom-reskin/TopStrapNav';
import NavSuperGateway from '../../components/pcom-reskin/NavSuperGateway';
import FooterSuperGateway from '../../components/pcom-reskin/FooterSuperGateway';





export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  return (
    <>
      <TopStrapNav />
      <NavSuperGateway isTransparent='true' isSticky='true' />

      {/*<div style={{'width': '500px', 'height': '500px', 'position': 'absolute', 'backgroundColor': '#05001C46', 'border': '3px solid red', 'zIndex':'10000'}}>asdf</div>*/}

      <div className="PCOM-GANKED home-page">
        <div className="page basicpage bgcolor--background-dark dots-lrDown-textureDark headerIsTransparent">
          <main role="main" id="main-content-starts">
             <div>
              <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
               <section style={{'paddingTop': '160px'}} className="column-control curve-bottom-left clip-target-bg-media has-padding-top--none min-height--ideal-hero aem-GridColumn aem-GridColumn--default--12">
                <div className="column-control--bg bg-1629177016 "></div>
                <div className="container">
                   <div className="row">
                    <div className="col-md-8 col-12">
                     <div className="target parbase section">
                      <div id="_content_global-store_sites_en-us_jcr_content_root_par-main_column_control_2113757651_par-col-1_embed" className="campaign ">
                         <div className="default embed section">
                          <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                          </div>
                         </div>
                      </div>
                     </div>
                     <div className="content-tile default-btn-type color--text-primary-inverse section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <h1>Pearson+<br />
                               eTextbooks for $10.99/mo
                            </h1>
                            <h4>Everything you need for class in one place. Bundle with extra study &amp; exam prep tools for an additional $6 per month.</h4>
                            <p><i>Minimum term of 4 months</i></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                     <div className="content-tile use-button-primary-inverse pad-top--0__pad-bottom--45__caption pad-left--0__pad-right--45__caption color--text-primary-inverse title-typesize--h1 subtitle-typesize--h4 section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <p><a href="https://www.pearson.com/en-us/pearsonplus/search.html" onClick={stopIt} className="stopIt OVERRIDE-HOME primarybtn radiant">Buy eTextbooks</a><br /></p>
                            <p>&nbsp;</p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                    <div className="col-md-4 col-12"></div>
                   </div>
                </div>
               </section>
               <section className="column-control flex-layout--pin-cta bgcolor--default has-padding-top--none has-padding-bottom--75 default-padding-horizontal use-margin-top-default default-min-height default-rule pull-up-5-percent aem-GridColumn aem-GridColumn--default--12">
                <div className="container">
                   <div className="row">
                    <div className="col-md-4 col-12">
                     <div className="OVERRIDE-HOME content-tile content-tile-panel-item-main behavior--none content-tile-portrait bgcolor--ui-01 default-links use-button-secondary default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h4 title-typeweight--semibold title-color--text-primary default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count corners-semiround-md section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <h3><b><span className="minor">K-12 EDUCATORS</span><br />
                               </b>Prepare your students for what’s next
                            </h3>
                            <p style={{'textAlign': 'left'}}>Our college and career readiness programs unlock opportunities for every student.</p>
                            <p style={{'textAlign': 'left'}}>
                              <a href="https://www.pearson.com/en-us/schools/college-readiness-career-education.html" onClick={stopIt} className="stopIt OVERRIDE-HOME card-button card-button-motion">
                                Check out our programs
                                <svg className="icon-arrow" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true">
                                  <g fill-rule="evenodd">
                                    <path className="icon-arrow__line-path" d="M13.13,7H1c-.55,0-1,.45-1,1s.45,1,1,1h12.13c.55,0,1-.45,1-1s-.45-1-1-1Z"/>
                                    <path className="icon-arrow__tip-path" d="M6.71,7.29L1.71,2.29c-.39-.39-1.02-.39-1.41,0-.39.39-.39,1.02,0,1.41,1.1,1.1,2.2,2.2,3.29,3.29h0s.28.29.28.29c.39.39.39,1.02,0,1.41l-.28.28h0c-1.1,1.11-2.2,2.2-3.29,3.3-.39.39-.39,1.02,0,1.41.39.39,1.02.39,1.41,0l5-5c.39-.39.39-1.02,0-1.41Z"/>
                                  </g>
                                </svg>
                              </a>

                            </p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                    <div className="col-md-4 col-12">
                     <div className="OVERRIDE-HOME content-tile content-tile-panel-item-main behavior--none content-tile-portrait bgcolor--ui-01 default-links use-button-secondary default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h4 title-typeweight--semibold title-color--text-primary default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count corners-semiround-md section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <h3><b><span className="minor">COLLEGE EDUCATORS</span><br />
                               </b>Empower your students’ success
                            </h3>
                            <p>We offer course materials, learning platforms, and resources catered to your subject area.</p>
                            <p>
                              <a href="https://www.pearson.com/en-us/higher-education/educators.html" onClick={stopIt} className="stopIt OVERRIDE-HOME card-button card-button-motion">
                                Explore classroom offerings
                                <svg className="icon-arrow" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true">
                                  <g fill-rule="evenodd">
                                    <path className="icon-arrow__line-path" d="M13.13,7H1c-.55,0-1,.45-1,1s.45,1,1,1h12.13c.55,0,1-.45,1-1s-.45-1-1-1Z"/>
                                    <path className="icon-arrow__tip-path" d="M6.71,7.29L1.71,2.29c-.39-.39-1.02-.39-1.41,0-.39.39-.39,1.02,0,1.41,1.1,1.1,2.2,2.2,3.29,3.29h0s.28.29.28.29c.39.39.39,1.02,0,1.41l-.28.28h0c-1.1,1.11-2.2,2.2-3.29,3.3-.39.39-.39,1.02,0,1.41.39.39,1.02.39,1.41,0l5-5c.39-.39.39-1.02,0-1.41Z"/>
                                  </g>
                                </svg>
                              </a>

                            </p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                    <div className="col-md-4 col-12">
                     <div className="OVERRIDE-HOME content-tile content-tile-panel-item-main behavior--none content-tile-portrait bgcolor--ui-01 default-links use-button-secondary default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption default-color title-typesize--h4 title-typeweight--semibold title-color--text-primary default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count corners-semiround-md section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <h3><b><span className="minor">COLLEGE STUDENTS</span><br />
                               </b>Find the tools you need for class
                            </h3>
                            <p>From eTextbooks to study videos, get what you need for the results you want.</p>
                            <p>
                              <a href="https://www.pearson.com/en-us/higher-education/students.html" onClick={stopIt} className="stopIt OVERRIDE-HOME card-button card-button-motion">
                                Browse learning tools
                                <svg className="icon-arrow" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true">
                                  <g fill-rule="evenodd">
                                    <path className="icon-arrow__line-path" d="M13.13,7H1c-.55,0-1,.45-1,1s.45,1,1,1h12.13c.55,0,1-.45,1-1s-.45-1-1-1Z"/>
                                    <path className="icon-arrow__tip-path" d="M6.71,7.29L1.71,2.29c-.39-.39-1.02-.39-1.41,0-.39.39-.39,1.02,0,1.41,1.1,1.1,2.2,2.2,3.29,3.29h0s.28.29.28.29c.39.39.39,1.02,0,1.41l-.28.28h0c-1.1,1.11-2.2,2.2-3.29,3.3-.39.39-.39,1.02,0,1.41.39.39,1.02.39,1.41,0l5-5c.39-.39.39-1.02,0-1.41Z"/>
                                  </g>
                                </svg>
                              </a>

                            </p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                   </div>
                </div>
               </section>
               <section className="column-control bgcolor--default has-padding-top--30 has-padding-bottom--30 aem-GridColumn aem-GridColumn--default--12">
                <div className="container">
                   <div className="row">
                    <div className="col-md-2 col-12"></div>
                    <div className="col-md-8 col-12">
                     <div className="content-tile color--text-primary-inverse title-color--text-primary-inverse section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <h2 style={{'textAlign': 'center'}}>Digital platforms that bring college courses to life</h2>
                            <p style={{'textAlign': 'center'}}>From biology to literature, our platforms reimagine how students learn — from the textbook to the classroom experience.</p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                    <div className="col-md-2 col-12"></div>
                   </div>
                </div>
               </section>
               <section className="column-control flex-layout--pin-cta bgcolor--default has-padding-top--none has-padding-bottom--90 default-padding-horizontal default-min-height default-rule aem-GridColumn aem-GridColumn--default--12">
                <div className="container">
                   <div className="row">
                    <div className="col-xl-3 col-md-6 col-12">
                     <div className="content-tile content-tile-panel-item-main behavior--none content-tile-portrait bgcolor--background-gray text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <picture style={{'--original-image-ratio': '2'}}>
                            <img loading="lazy" src={imgPromoA} alt="" role="presentation" />
                          </picture>
                          <div className="content-tile__figcaption">
                           <h3 className="content-tile__title">MyLab</h3>
                           <div className="content-tile-text rte-container">
                            <p>As the world’s leading online homework, tutorial, and assessment platform, MyLab courses are designed to improve results for all higher education students.</p>
                            <p><a href="https://www.pearson.com/en-us/higher-education/products-services/mylab.html" onClick={stopIt} className="stopIt">Learn about or access MyLab</a></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                    <div className="col-xl-3 col-md-6 col-12">
                     <div className="content-tile content-tile-panel-item-main behavior--none content-tile-portrait bgcolor--background-gray text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <picture style={{'--original-image-ratio': '2'}}>
                            <img loading="lazy" src={imgPromoB} alt="" role="presentation" />
                          </picture>
                          <div className="content-tile__figcaption">
                           <h3 className="content-tile__title">Mastering</h3>
                           <div className="content-tile-text rte-container">
                            <p>Tutorials, analytics, and feedback make Mastering the premier platform to develop and empower future scientists.</p>
                            <p><a href="https://www.pearson.com/en-us/higher-education/products-services/mastering.html" onClick={stopIt} className="stopIt">Learn about or access Mastering</a></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                    <div className="col-xl-3 col-md-6 col-12">
                     <div className="content-tile content-tile-panel-item-main behavior--none content-tile-portrait bgcolor--background-gray text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <picture style={{'--original-image-ratio': '2'}}>
                           <img loading="lazy" src={imgPromoC} alt="" role="presentation" />
                          </picture>
                          <div className="content-tile__figcaption">
                           <h3 className="content-tile__title">Revel</h3>
                           <div className="content-tile-text rte-container">
                            <p>Students can read and practice in one continuous experience in this engaging blend of author content, media, and assessments.</p>
                            <p><a href="https://www.pearson.com/en-us/higher-education/products-services/revel.html" onClick={stopIt} className="stopIt">Learn about or access Revel</a></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                    <div className="col-xl-3 col-md-6 col-12">
                     <div className="content-tile content-tile-panel-item-main behavior--none content-tile-portrait bgcolor--background-gray text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h5 default-title-typeweight default-subtitle-typesize default-subtitle-typeweight default-subtitle-color default-content-tile-text--col-count section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <picture style={{'--original-image-ratio': '2'}}>
                           <img loading="lazy" src={imgPromoD} alt="" role="presentation" />
                          </picture>
                          <div className="content-tile__figcaption">
                           <h3 className="content-tile__title">Pearson+</h3>
                           <div className="content-tile-text rte-container">
                            <p>Your eTextbooks, study videos and Job Match – all in one place, at low monthly prices.</p>
                            <p><a href="https://www.pearson.com/en-us/pearsonplus.html" onClick={stopIt} className="stopIt">Learn about or access Pearson+</a></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                   </div>
                </div>
               </section>
               <section className="column-control bgcolor--background-light has-padding-top--30 has-padding-bottom--30 aem-GridColumn aem-GridColumn--default--12">
                <div className="container">
                   <div className="row">
                    <div className="col-12">
                     <div className="content-tile default-design-template content-tile-landscape--50-50 vertically-centered use-button-primary pad--45__figure pad-eq-vertical--45__caption color--text-primary title-typesize--kilo section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <picture style={{'--original-image-ratio': '1.313'}}>
                           <img loading="lazy" src={imgPromoE} alt="" role="presentation" />
                          </picture>
                          <div className="content-tile__figcaption">
                           <h2 className="content-tile__title">Study and exam prep with Channels</h2>
                           <div className="content-tile-text rte-container">
                            <h4>Video lessons that break down concepts. Practice exams to test your knowledge. The tools you need to get the grade, available in Pearson+.</h4>
                            <p><a href="https://www.pearson.com/channels?CEP=pearsonHP" onClick={stopIt} className="OVERRIDE-HOME primarybtn stopIt">Start learning</a></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                   </div>
                </div>
               </section>
               <section className="column-control bgcolor--background-medium aem-GridColumn aem-GridColumn--default--12">
                <div className="container">
                   <div className="row">
                    <div className="col-12">
                     <div className="OVERRIDE-HOME content-tile content-tile-color-block--full-img bgcolor--background-dark use-button-tertiary-inverse corners-semiround-md section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <picture style={{'--original-image-ratio': '2.4'}}>
                           <img loading="lazy" src={imgPromoF} alt="" role="presentation" />
                          </picture>
                          <div className="content-tile__figcaption">
                           <h2 className="content-tile__title">Get ahead of the curve</h2>
                           <div className="content-tile-text rte-container">
                            <p>Prepare your students for college-level learning and career development with AP<sup>®</sup>, Honors &amp; Electives programs, Career &amp; Technical Education, and Dual Enrollment courses.</p>
                            <p><a href="https://www.pearson.com/en-us/schools/college-readiness-career-education.html" onClick={stopIt} className="stopIt">Explore programs</a></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                   </div>
                </div>
               </section>
               <section className="column-control bgcolor--ui-01 has-padding-top--90 has-padding-bottom--30 aem-GridColumn aem-GridColumn--default--12">
                <div className="container">
                   <div className="row">
                    <div className="col-md-2 col-12"></div>
                    <div className="col-md-8 col-12">
                     <div className="content-tile section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <h2 style={{'textAlign': 'center'}}>Top learning resources for every stage of life</h2>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                    <div className="col-md-2 col-12"></div>
                   </div>
                </div>
               </section>
               <section className="column-control flex-layout--pin-cta bgcolor--ui-01 has-padding-top--none has-padding-bottom--90 default-padding-horizontal default-min-height default-rule aem-GridColumn aem-GridColumn--default--12">
                <div className="container">
                   <div className="row">
                    <div className="col-md-4 col-12">
                     <div className="content-tile c-frameless behavior--none content-tile-portrait bgcolor--default text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h3 default-title-typeweight subtitle-typesize--p subtitle-typeweight--light default-subtitle-color default-content-tile-text--col-count section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <h3><span className="minor">FOR SCHOOL</span><br />
                               Wide-ranging help for K-12 students and faculty
                            </h3>
                            <p>Browse our effective, engaging learning solutions and professional development resources.</p>
                            <p><a href="https://www.pearson.com/en-us/schools.html" onClick={stopIt} className="stopIt">See what we offer for schools</a></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                    <div className="col-md-4 col-12">
                     <div className="content-tile c-frameless behavior--none content-tile-portrait bgcolor--default text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h3 default-title-typeweight subtitle-typesize--p subtitle-typeweight--light default-subtitle-color default-content-tile-text--col-count section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <h3><span className="minor">FOR COLLEGE</span><br />
                               Innovative solutions for higher education
                            </h3>
                            <p>Shop for your courses, explore resources by course or teaching discipline, and discover our digital learning platforms.&nbsp;</p>
                            <p><a href="https://www.pearson.com/en-us/higher-education.html" onClick={stopIt} className="stopIt">See what we offer for college</a></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                    <div className="col-md-4 col-12">
                     <div className="content-tile c-frameless behavior--none content-tile-portrait bgcolor--default text-primary-link default-btn-type default-btn-size default-btn-width pad--default__figure pad-eq-vertical--default__caption pad-eq-horizontal--default__caption title-typesize--h3 default-title-typeweight subtitle-typesize--p subtitle-typeweight--light default-subtitle-color default-content-tile-text--col-count section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <h3><span className="minor">FOR WORK</span><br />
                               Practical professional development
                            </h3>
                            <p>We work with HR and business leaders to solve workforce challenges of today and tomorrow.</p>
                            <p><a href="https://www.pearson.com/en-us/work.html" onClick={stopIt} className="stopIt">See what we offer for work</a></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                   </div>
                </div>
               </section>
               <section className="column-control bgcolor--ui-04 has-padding-top--15 has-padding-bottom--15 aem-GridColumn aem-GridColumn--default--12">
                <div className="container">
                   <div className="row">
                    <div className="col-12">
                     <div className="content-tile pad-eq-vertical--0__caption color--text-primary-inverse section">
                      <div className="content-tile-container">
                         <div className="content-tile__figure">
                          <div className="content-tile__figcaption">
                           <div className="content-tile-text rte-container">
                            <p style={{'textAlign': 'center'}}><span className="minor">AP<sup>®</sup> is a trademark registered and/or owned by the College Board, which was not involved in the production of, and does not endorse, these programs.</span></p>
                           </div>
                          </div>
                         </div>
                      </div>
                     </div>
                    </div>
                   </div>
                </div>
               </section>
              </div>
             </div>
             <div aria-live="assertive" style={{'position': 'absolute', 'color': 'transparent', 'bottom': '26p'}} id="accessibility__announcement"></div>
             <a className="to-top-button is-show" href="#main-content-starts">
             <span className="screenreader">Back to</span>
             <span className="to-top-button__title">top</span>
             </a>
          </main>
        </div>

        <FooterSuperGateway />

      </div>
    </>
  );
}
