
import React, { useState } from "react";

import '../../style/pcom/_isbnSearch-filter.scss';
import CheckButton from "../../components/global/CheckButton";

export default function Filters() {

	/**check buttons on the filtering */
	const [formatChecks, setFormatChecks] = useState([
		{ id: "eTextbook", label: "eTextbook", isChecked: false },
		{ id: "Paperback", label: "Paperback", isChecked: false },
		{ id: "Instant Access", label: "Instant Access", isChecked: false },
		{ id: "Lose-Leaf", label: "Lose-Leaf", isChecked: false },
		{ id: "Hardcover", label: "Hardcover", isChecked: false },
	]);

	const [priceRange, setPriceRange] = useState([
		{ id: "$4.99 - $49.99", label: "$4.99 - $49.99", isChecked: false },
		{ id: "$50.00 - $84.99", label: "$50.00 - $84.99", isChecked: false },
		{ id: "$120.00 - $149.99", label: "$120.00 - $149.99", isChecked: false},
		{ id: "Over $150.00", label: "Over $150.00", isChecked: false },
	]);
    const [edition, setEdition] = useState(false);
	const handleFormatChecks = (id) => {
		setFormatChecks((prevButtons) =>
			prevButtons.map((button) =>
				button.id === id
					? { ...button, isChecked: !button.isChecked }
					: button
			)
		);
	};
	const handlePriceCheck = (id) => {
		setPriceRange((prevButtons) =>
			prevButtons.map((button) =>
				button.id === id
					? { ...button, isChecked: !button.isChecked }
					: button
			)
		);
	};


	return (
		<>
			<h2 className="column-title">Filter by</h2>

            <div className="filter">
				<h3 className="filter-title">Subjects</h3>
				<ul className="subject-list">
					{["Careers & Trades", "Engineering", "English", "Mathematics", "Science"].map((link) => (
						<li key={link} className="filter-list-item">{link}</li>
					))}
				</ul>
                <a className="show-more">Show more +</a>
                </div>

			<div className="filter">
				<h3 className="filter-title">Formats</h3>
				{formatChecks.map((button) => (
					<CheckButton
						key={button.id}
						id={button.id}
						label={button.label}
						checked={button.isChecked}
						handleCheckChange={() => handleFormatChecks(button.id)}
					/>
				))}
				<a className="show-more">Show more +</a>
			</div>

			<div className="filter">
				<h3 className="filter-title">Price Range</h3>
				{priceRange.map((button) => (
					<CheckButton
						key={button.id}
						id={button.id}
						label={button.label}
						checked={button.isChecked}
						handleCheckChange={() => handlePriceCheck(button.id)}
					/>
				))}
				<a className="show-more">Show more +</a>
			</div>

			<div className="filter">
				<h3 className="filter-title">Editions</h3>
				<CheckButton
					id="New Editions"
					label="New Editions"
					checked={edition}
					handleCheckChange={() => setEdition((prev) => !prev)}
				/>
				<a className="show-more">Show more +</a>
			</div>
		</>
	);
}
