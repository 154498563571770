/** @format */

import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../style/pcom/_pdp-overViewTab.scss';

export default function BookOverviewTab({ content }) {
	const tabsToDisplay = [
		{ title: 'Title overview', key: 'title' },
		{ title: 'Table of contents', key: 'contents' },
		{ title: 'Accessibility', key: 'accessibility' },
	];

	return (
		<Tabs
			className="book-tab-container"
			defaultIndex={0}
		>
			<TabList className="tab-list">
				{tabsToDisplay.map((tab, index) => (
					<Tab
						key={index}
						className="tab"
					>
						<span>{tab.title}</span>
					</Tab>
				))}
			</TabList>

			<TabPanel className="tab-content">
				<p>{content.overview}</p>
			</TabPanel>
			<TabPanel className="tab-content">
				<p>{content.toc}</p>
			</TabPanel>
			<TabPanel className="tab-content">
				<p>{content.accessibility}</p>
			</TabPanel>
		</Tabs>
	);
}
