/** @format */
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { radioSelect, openAccordion } from "../../recoil/recoilAtom";
/*css*/
import "../../style/pcom/_checkout.scss";
/**components */
import Nav from "../../components/pcom-reskin/Nav";
import Footer from "../../components/global/Footer";

/*icon*/
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import needHelpIcon from "../../assets/image/pdp/need-help-icon.svg";
/*data*/
import bookData from "../../assets/data/book.json";
import CourseData from '../../assets/data/courses.json';
/**components */
import CreditCard from "../../components/checkout/CreditCard";
import PayPal from "../../components/checkout/PayPal";
import Summary from "../../components/checkout/Summary";
import TotalPrice from "../../components/checkout/TotalPrice";
import ApplePay from "../../components/checkout/ApplePay";

export default function Checkout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { subject } = useParams();
  const selectedBook = bookData.find((book) => book.subject === subject);
  const matchedCourse = CourseData.find((course) => course.productSubject === subject);
  const { title, bookCover, platform, shortTitle } = selectedBook;
  const [selectedOption, setSelectedOption] = useRecoilState(radioSelect);
  const [detailOpen, setDetailOpen] = useRecoilState(openAccordion);

  const toggleDetail = () => {
    setDetailOpen(!detailOpen);
  };

  const [selectedPayment, setSelectedPayment] = useState("");
  const handleRadioChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  /**Nav go bak title change */
  const location = useLocation();
  const { NavReturnTitle } = location.state || { NavReturnTitle: 'Change your selection' };

  return (
    <>
      {NavReturnTitle ? (
        <Nav mode="checkout" returnTitle="Return to cart" pageBack={-2}/>
      ) : (
        <Nav mode="checkout" returnTitle="Change your selection" pageBack={-1}/>
      )}

      <div className="PCOM">
        <div className="checkout-wrapper">
          <main>
            <h1 className="page-title">Secured checkout</h1>
            <div className="body-wrapper">
              <section className="payment">
                <h2 className="payment-title">
                  1. Payment & Billing
                </h2>
                <CreditCard
                  subject={subject}
                  selectedPayment={selectedPayment}
                  handleRadioChange={handleRadioChange}
                  disabled='true'
                />
                <PayPal
                  subject={subject}
                  selectedPayment={selectedPayment}
                  handleRadioChange={handleRadioChange}
                />
                <ApplePay
                  subject={subject}
                  selectedOption={selectedOption}
                  platform={platform}
                  selectedPayment={selectedPayment}
                  handleRadioChange={handleRadioChange}
                  disabled='true'
                />
              </section>

              <section className="order-summary">
                <h1>Order Summary</h1>
                <div className="order-content">
                  <div className="book-info">
                    <div className="img-text-wrapper">
                      <img
                        src={require(`../../assets${bookCover}`)}
                        alt={title}
                      />

                      <div className="text-info">
                        <h3>
                          {platform}
                          {subject
                            .charAt(0)
                            .toUpperCase() +
                            subject.slice(1)}
                          with Pearson eText for
                          {shortTitle}
                        </h3>
                        <p>
                          ISBN-13:
                          {selectedOption[2].substring(
                            0,
                            13
                          )}
                        </p>
                        <p className="price">
                          <span>Item price</span>$
                          {selectedOption[0]}
                        </p>
                        <p>Qty : 1</p>
                        {selectedOption[4] ===
                        "Up to 18 weeks" ? (
                          <p>
                            Single-term access (up to
                            18weeks)
                          </p>
                        ) : (
                          <p>
                            Multi-term access (24
                            months)
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="course-detail-container">
                    <div
                      className="header"
                      onClick={toggleDetail}
                    >
                      <h6>Course details</h6>

                      {detailOpen ? (
                        <IoIosArrowDown
                          className="arrow"
                          size={18}
                        />
                      ) : (
                        <IoIosArrowUp
                          className="arrow"
                          size={18}
                        />
                      )}
                    </div>
                    {!detailOpen && (
                    <div className="course-detail-info">
                      <p>Course: {matchedCourse.title}</p>
                      <p>Course ID: {matchedCourse.id}</p>
                      <p>Instructor: {matchedCourse.instructor}</p>
                      <p>End Date: {matchedCourse.endDate}</p>
                    </div>
                    )}
                  </div>
                  {selectedOption[3] === "bundle" ? (
                    <Summary
                      title={title}
                      selectedOption={selectedOption}
                      style={{ width: "85px" }}
                    />
                  ) : null}
                </div>

                <TotalPrice
                  selectedOption={selectedOption}
                  style={{ backgroundColor: "#F5F5F5" }}
                />
              </section>
            </div>
          </main>
        </div>

        <section>
          <p className="need-help-footer">
            <img src={needHelpIcon} alt="help-icon" />
            <span>Need help?</span>
            <span className="fake-link">Get in touch</span>
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
}
