/** @format */

import React from "react";
import styled from "styled-components";

import CartSvg from "../../assets/image/pdp/cart-icon.svg";
import CartItem from "../../assets/image/pdp/cart-item.svg";
import HelpIcon from "../../assets/image/pdp/help-icon.svg";

export default function NavICons(props) {
	return (
		<IconContainer style={props.style}>
			<CartIcon />
			<HelpButton />
		</IconContainer>
	);
}

const IconContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const HelpButton = styled.button`
	background-color: transparent;
	border: none;
	outline: none;
	align-items: center;
	justify-content: center;
	height: inherit;
	padding: 0;
	width: 45px;
	height: 30px;
	margin: 0;
	padding: 0;
	position: relative;
	background: url(${HelpIcon}) no-repeat center;
`;

const CartIcon = styled.button`
	background-color: transparent;
	border: none;
	outline: none;
	align-items: center;
	justify-content: center;
	height: inherit;
	padding: 0;
	width: 45px;
	height: 30px;
	margin: 0;
	padding: 0;
	position: relative;

	&::before {
		content: "";
		display: inline-block;
		width: 31px;
		height: 18px;
		background: url(${CartItem}) no-repeat center;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}

	&::after {
		content: "";
		display: inline-block;
		width: 25px;
		height: 25px;
		background: url(${CartSvg}) no-repeat center;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 0;
	}
`;
