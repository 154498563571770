/** @format */

import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { radioSelect } from "../../recoil/recoilAtom";

import "../../style/pcom/_cart.scss";

/**components */
import TopStrapNav from '../../components/pcom-reskin/TopStrapNav';
import Nav from "../../components/pcom-reskin/Nav";
import FooterCollege from '../../components/pcom-reskin/FooterCollege';

/*data*/
import bookData from "../../assets/data/book.json";
/*component*/
import ErrorBanner from "../../components/cart/ErrorBanner";
import BundlePack from "../../components/cart/BundlePack";
import InputBox from "../../components/cart/InputBox";
import Summary from "../../components/cart/Summary";
import OrderHelp from "../../components/cart/OrderHelp";

export default function Cart() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const { subject } = useParams();
  const selectedBook = bookData.find((book) => book.subject === subject);
  const { title, bookCover, publish, author, channel } = selectedBook;
  const [selectedOption, setSelectedOption] = useRecoilState(radioSelect);

  console.debug('selectedOption');
  console.debug(selectedOption);

  return (
    <>
      <TopStrapNav collegeActive='true' />
      <Nav isSticky='true' />

      <div className="PCOM">
        <div className="cart-wrapper">
          <main>
            <h1 className="page-title">Your cart</h1>
            <ErrorBanner />

            <div className="section-wrapper">
              <section className="cart-body">
                <div className="book-body-content">
                  <div className="book-info">
                    <img
                      src={require(`../../assets${bookCover}`)}
                      alt={title}
                    />
                    <div className="text-info">
                      <h3>{title}</h3>
                      <p>ISBN-13: {selectedOption[2].substring(0, 13)}</p>
                      {selectedOption[4]==="Up to 18 weeks"? <p>Single-term access (up to 18 weeks)</p>: <p>Multi-term access (up to 24 months) </p>}
                    </div>
                  </div>
                  <div className="price-info">
                    <h4>${selectedOption[0]}</h4>
                    <p>Qty 1</p>
                    <button type="button">Remove</button>
                    <button type="button">
                      Save for later
                    </button>
                  </div>
                </div>

                <InputBox/>

                {selectedOption[3] === "bundle" ? (
                  <BundlePack
                    channel={channel}
                    title={title}
                    selectedOption={selectedOption}
                  />
                ) : null}
              </section>

              <section className="cart-price">
                <Summary selectedOption={selectedOption} />
              </section>
            </div>

            <OrderHelp />
          </main>
        </div>
      </div>
      <div className="PCOM-GANKED">
        <FooterCollege />
      </div>
    </>
  );
}
