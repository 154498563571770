/** @format */

import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { radioSelect } from '../../recoil/recoilAtom';
import '../../style/pcom/_pdp-bundleOption.scss';
import RadioInput from './RadioInput';

export default function RegularOption({ regularItem, onOptionChange, platform, title}) {
	const { subject } = useParams();
	const { regular } = regularItem.option;
	const [selectedOption, setSelectedOption] = useRecoilState(radioSelect);
	const handleRegularOption = (value) => {
		onOptionChange(value);
	};
	useEffect(() => {
		if (subject == 'sociology') {
			setSelectedOption(regular.radioLabel.single);
		}
	}, []);

	return (
		<div className={`regular-option-container ${
				selectedOption === regular.radioLabel.single || selectedOption === regular.radioLabel.multi? 'radio-checked': ''}`}>

			<div className="option-info">

				{/*Only show card header if product type is MASTERING or MYLAB*/}
				{platform !== "eTextbook" && (
					<>
						{selectedOption === regular.radioLabel.multi ? (
				  			<h4>{platform} <span className="capitalize">{subject} </span>with Pearson eText ({regular.radioLabel.multi[3].replace('Up to ', '')}) for {title}</h4>
						) : (
							<h4>{platform} <span className="capitalize">{subject} </span>with Pearson eText ({regular.radioLabel.single[3].replace('Up to ', '')}) for {title}</h4>
						)}
					</>
				)}

				<RadioInput
					id="regular-single-option"
					name="regularOption"
					label={subject === 'chemistry' ? 'eTextbook ' : 'Single-term access'}
					value={regular.radioLabel.single}
					checked={regular.radioLabel.single === selectedOption || selectedOption === null}
					onChange={handleRegularOption}
          disabled='true'
				/>
				{regular.radioLabel.multi.length > 0 && (
					<RadioInput
						id="regular-multi-option"
						name="regularOption"
						label="Multi-term access"
						value={regular.radioLabel.multi}
						checked={regular.radioLabel.multi === selectedOption}
						onChange={() => handleRegularOption(regular.radioLabel.multi)}
            disabled='true'
					/>
				)}
			</div>
		</div>
	);
}
