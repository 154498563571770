/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import "../../style/pcom/_buttons.scss";

export default function PrimaryButton({
	title,
	style,
	route,
	data,
	selectedOption,
}) {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(route, { state: { data, selectedOption } });
	};
	return (
		<button
			className="primary-button-container"
			style={style}
			onClick={handleClick}
		>
			{title}
		</button>
	);
}
