/** @format */

import React from "react";
import styled from "styled-components";

export default function CardInput({ type, label, placeholder, disabled, value }) {
	return (
		<InputLabel>
			<Text>{label}</Text>
			<CheckoutInput
				type={type}
				placeholder={placeholder}
				disabled={disabled}
				value={value}
			/>
		</InputLabel>
	);
}

const InputLabel = styled.label`
	display: flex;
	flex-direction: column;
`;

const Text = styled.p`
	font-family: var(--typefamily--primary-stack);
	color: #333;
	font-size: 0.875rem;
	font-weight: 600;
	text-align: left;
`;

const CheckoutInput = styled.input`
	height: 48px;
	border-radius: 4px;
	border: 1px solid #ccc;
	background: var(--ui-elements-field, #fff);
	padding: 12px 13px;
	outline: none;
	font-size: 1rem;
	color: #333;
	font-weight: 400;
	margin-top: 8px;
	&:focus,
	&:active {
		border: 3px solid #2192fb;
	}
	&:hover {
		border: 1px solid #151515;
	}
	&:disabled {
		color: var(--interactive-disabled-interactive-02, #919191);
		border: 1px solid var(--interactive-disabled-interactive-02, #919191);
		background: var(--ui-elements-ui-01, #f5f5f5);
		pointer-events: none;
	}
`;
