import React from "react";
import { FaChevronDown } from "react-icons/fa";
import "../../style/pcom/_select.scss";
export default function Select({ defaultValue, options }) {
	if (!Array.isArray(options)) {
		console.error("error.");
		return null;
	}

	return (
		<div
			className="select-container"
			style={{ position: "relative", display: "inline-block" }}
		>
			<select defaultValue={defaultValue}>
				<FaChevronDown />
				{options.map((option, index) => (
					<option key={index} value={option}>
						{option}
					</option>
				))}
			</select>
			<FaChevronDown className="down-arrow" />
		</div>
	);
}
