/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { PcomLoginState } from "../../recoil/recoilAtom";
import "../../style/pcom/_signin.scss";

import Footer from "../../components/global/Footer";
import PsoLogo from "../../components/global/PsoLogo";

export default function PcomSignin() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const { subject } = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useRecoilState(PcomLoginState);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoggedIn(true);
    navigate(`${process.env.PUBLIC_URL}/${subject}/checkout`);
  };

  return (
    <>
    <div className="PCOM">
      <div className="nav-wrapper">
        <nav>
          <div className="menu-wrapper">
            <PsoLogo />
          </div>
        </nav>
      </div>
      <div className="sign-in-page">
        <div className="left-column"></div>
        <div className="right-column">
          <h1>Sign in</h1>
          <p>
            New to Pearson? <span>Create an account</span>
          </p>
          <form onSubmit={handleLogin}>
            <div>
              <label>Email address or username</label>
              <input
                type="text"
                name="username"
                id="username"
                value='ux'
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                name="password"
                id="password"
                value='1111'
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <p>
              <span>Trouble signing in?</span>
            </p>
            <button
              type="submit"
              className="primary-button-container"
              style={{ padding: "10px 0" }}
            >Sign in
            </button>
            <p className="consent">
              By signing in, you agree to our
              <span>Terms of use</span> and acknowledge our
              <span> Privacy notice.</span>
            </p>
          </form>
        </div>
      </div>

    </div>
    <Footer />
    </>
  );
}
